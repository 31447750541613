<template>
  <div class="home">
    <!-- v-if="delay" -->
    <AochenHead
      :InstanceList="InstanceList"
      :countDownObj="countDownObj"
      @GroupList="GroupList"
      @updateGroup="getinstancelist"
      @signInClick="signInClick_father"
    />
    <div class="main">
      <div v-if="gonggeshow == 1">
        <!-- v-if="delay" -->
        <Singlecard
          :gonggeshow="gonggeshow"
          :InstanceList="InstanceList"
          :buyShow="buyShow"
          :getShow="getShow"
        />
      </div>
      <div v-if="gonggeshow == 4 || gonggeshow == 9 || gonggeshow == 16">
        <!-- v-if="delay" -->
        <Fourboxgrid
          :gonggeshow="gonggeshow"
          :InstanceList="InstanceList"
          :getShow="getShow"
        />
      </div>
      <!-- <div v-if="gonggeshow == 9">
        <Nineboxgrid
          v-if="flag"
          :gonggeshow="gonggeshow"
          :InstanceList="InstanceList"
        />
      </div> -->
    </div>
    <!-- 进入时的弹窗 -->
    <div class="remincla" v-if="reminder">
      <van-popup
        class="webImageImgPopup"
        :style="{ width: reminder.imageUrl == null ? '80%' : '80%' }"
        v-model="reminder_show"
        v-if="reminder.imageUrl != null || reminder.content != null"
      >
        <div class="remi_img">
          <img
            class="webImageImg"
            v-if="reminder.imageUrl != null"
            :src="reminder.imageUrl"
            alt=""
            @click="remintz"
          />
          <div v-else class="remi_bt">
            <div class="remi_btP1">{{ reminder.title }}</div>
            <div class="remi_btP2" style="white-space: pre-wrap">
              {{ reminder.content }}
            </div>
          </div>
        </div>
        <div class="remi_guanbi" @click="reminder_show = false">
          <img src="../../assets/icons/guanbi.png" alt="" />
        </div>
      </van-popup>
    </div>
    <!-- <BgsSdk ref="child_BgsSdk" /> -->
    <AochenFooter></AochenFooter>
    <toAppStore :isTitle="true" ref="sonShow"></toAppStore>
    <SignInPopup
      :signInShow="signInShow"
      @closeChildDialog="closeChildDialog"
      v-if="delay"
    ></SignInPopup>
    <div
      class="countDownJump"
      @click="countDownJump"
      v-if="countDownIconShow && !timeCountDown"
    >
      <img
        class="closeCountDown"
        src="../../assets/img/activity/newGroupBooking/closeCountDown.png"
        alt=""
        @click.stop="CountDownClose"
      />
    </div>
    <div
      class="countDownOpenJump"
      @click="countDownJump"
      v-if="countDownIconShow && timeCountDown"
    >
      <img
        class="closeCountDown"
        src="../../assets/img/activity/newGroupBooking/closeCountDown.png"
        alt=""
        @click.stop="CountDownClose"
      />
      <!-- <div class="timeName"></div> -->
      <van-count-down
        class="timeName"
        :time="timeCountDown"
        format="DD 天 HH 时 mm 分 ss 秒"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AochenFooter from "@/components/AochenFooter";
import AochenHead from "@/components/AochenHead";
import BgsSdk from "@/components/BgsSdk";
import Floating from "@/components/Floating.vue";
import Singlecard from "@/components/cell/Singlecard.vue";
import Fourboxgrid from "@/components/cell/Four_boxgrid.vue";
import Nineboxgrid from "@/components/cell/nine_boxgrid.vue";
import SignInPopup from "@/components/cell/SignInPopup.vue";
import toAppStore from "@/components/activityGather/toAppStore.vue";
import {
  instancelist,
  activityreminder,
  regWelfare,
  groupAndRedData,
} from "../../api/index.js";
import { Toast } from "vant";
import { mapState, mapMutations } from "vuex";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default {
  name: "HomeView",
  provide() {
    // 在爷爷组件中提供一个方法
    return {
      grandpaGetinstancelist: this.getinstancelist,
    };
  },
  components: {
    // 底部
    AochenFooter,
    // 头部
    AochenHead,
    // sdk
    BgsSdk,
    // sdk悬浮球
    Floating,
    // 单卡片内容区域
    Singlecard,
    // 4宫格
    Fourboxgrid,
    // 9宫格
    Nineboxgrid,
    // 苹果跳appstore弹窗
    toAppStore,
    // 签到
    SignInPopup,
  },
  data() {
    return {
      integer: 0,
      InstanceList: [],
      groupingForList: [],
      userdata: null,
      reminder: null,
      reminder_show: false,
      gonggeshow: 1,
      flag: false,
      delay: false,
      buyShow: false,
      getShow: false,
      timer: null,
      signInShow: false,
      countDownObj: {},
      timeCountDown: 0,
    };
  },
  mounted() {
    // const fpPromise = new Promise((resolve, reject) => {
    //     const script = document.createElement('script')
    //     script.onload = resolve
    //     script.onerror = reject
    //     script.async = true
    //     script.src = 'https://cdn.jsdelivr.net/npm/'
    //         + '@fingerprintjs/fingerprintjs@3/dist/fp.min.js'
    //     document.head.appendChild(script)
    // })
    //     .then(() => FingerprintJS.load())

    // // Get the visitor identifier when you need it.
    // fpPromise.then(fp => fp.get()).then(result => {
    //     // This is the visitor identifier:
    //     const visitorId = result.visitorId
    //     console.log('唯一标识:' + visitorId)
    // })
    let that = this;
    let channelCode;
    let _channelCode = JSON.parse(sessionStorage.getItem("channelCode"));
    if (that.$route.query.channelCode) {
      if (this.isBase64(that.$route.query.channelCode)) {
        channelCode = { channelCode: atob(that.$route.query.channelCode) };
      } else {
        channelCode = {
          channelCode: that.$route.query.channelCode.replace(/"/g, ""),
        };
      }
      sessionStorage.setItem("channelCode", JSON.stringify(channelCode));
    } else {
      if (!_channelCode) {
        channelCode = { channelCode: "" };
        sessionStorage.setItem("channelCode", JSON.stringify(channelCode));
      }
    }
    if (Boolean(that.$route.query.applePush)) {
      this.SET_APPLEPUSHSHOW(Boolean(that.$route.query.applePush));
    }
    that.userdata = JSON.parse(localStorage.getItem("data"));
    if (that.userdata) {
      that.getactivityreminder();
      if (this.recordLong) {
        that.getRegWelfare();
      }
    }
    that.getinstancelist();
    // let gongge = sessionStorage.getItem("gonggeshow");
    // if (gongge != null) {
    //   this.gonggeshow = gongge-1;
    // }
    // 延迟渲染子组件
    // this.timer = setInterval(() => {
    this.flag = true;
    // }, 200);
    // this.$nextTick(() => {
    //   this.flag = true;
    // });
    let show = localStorage.getItem("homeMessage");
    if (show != null) {
      this.gonggeqiehuan(Number(show));
    }
    if (this.countDownIconShow) {
      this.getGroupBuying();
    }
  },
  computed: {
    //数组写法
    ...mapState("common", ["recordLong", "applePushShow", "countDownIconShow"]),
  },
  methods: {
    getGroupBuying() {
      groupAndRedData().then((res) => {
        if (res.code == 200) {
          const countdownArr = res.data.groupBuying
            .filter((item) => item.isEnd == 0) // 过滤
            .map((item) => item.countdown)
            .sort(); // 取字段
          if (countdownArr.length > 0) {
            this.timeCountDown = countdownArr[0];
          }
        }
      });
    },
    signInClick_father(e) {
      this.signInShow = e;
    },
    closeChildDialog(e) {
      this.signInShow = e;
      this.getinstancelist();
    },
    ...mapMutations("common", [
      "SET_RECORDLONG",
      "SET_APPLEPUSHSHOW",
      "SET_COUNTDOWNICONSHOW",
    ]),
    getRegWelfare() {
      let webLog = {};
      let userAgent = navigator.userAgent; // 获取微信版本
      let m1 = userAgent.match(/MicroMessenger.*?(?= )/);
      if (m1 && m1.length > 0) {
        webLog.wechat = m1[0];
      } // 苹果手机
      if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
        // 获取设备名
        if (userAgent.includes("iPad")) {
          webLog.device = "iPad";
        } else {
          webLog.device = "iPhone";
        } // 获取操作系统版本
        m1 = userAgent.match(/iPhone OS .*?(?= )/);
        if (m1 && m1.length > 0) {
          webLog.system = m1[0];
        }
      } // 安卓手机
      if (userAgent.includes("Android")) {
        // 获取设备名
        m1 = userAgent.match(/Android.*; ?(.*(?= Build))/);
        if (m1 && m1.length > 1) {
          webLog.device = m1[1];
        } // 获取操作系统版本
        m1 = userAgent.match(/Android.*?(?=;)/);
        if (m1 && m1.length > 0) {
          webLog.system = m1[0];
        }
      }
      let data = {
        loginType: this.userdata.loginType,
        equipmentModel:
          JSON.stringify(webLog) === "{}" || !webLog.device || !webLog.system
            ? ""
            : webLog.device + "_" + webLog.system.replace(/\s+/g, ""),
      };
      regWelfare(data).then((res) => {
        this.SET_RECORDLONG(false);
      });
    },
    isBase64(str) {
      const regex =
        /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;
      return regex.test(str);
    },
    // 切换宫格
    gonggeqiehuan(e) {
      this.$nextTick(() => {
        this.gonggeshow = e;
        // sessionStorage.setItem("gonggeshow", e);
      });
    },
    // 启动云手机
    BgsSdca(em) {
      this.userdata = JSON.parse(localStorage.getItem("data"));
      if (this.userdata) {
        // this.$refs.child_BgsSdk.getserverToken(em);
        let _em = this.groupingForList.find(function (item) {
          return item.id == em.id;
        });
        localStorage.setItem("em", JSON.stringify(_em));
        switch (_em.channelCode) {
          case "baidu":
            this.$router.push({
              params: {
                e: _em,
              },
              name: "BgsSdk",
            });
            break;
          case "huoshan":
            this.$router.push({
              params: {
                e: _em,
              },
              name: "VeSdk",
            });
            break;
          case "intelligent":
            this.$router.push({
              params: {
                e: _em,
              },
              name: "NzSdk",
            });
            break;
          default:
            console.log("0");
        }
      } else {
        this.$router.push("/login");
      }
    },
    // 获取所有设备信息
    async getinstancelist() {
      let that = this;
      let list = JSON.parse(sessionStorage.getItem("selectiveGroup"));
      await instancelist().then((res) => {
        let Show = JSON.parse(sessionStorage.getItem("countDownShow"));
        let numGroup = JSON.parse(localStorage.getItem("homeMessage")) == 1;
        this.getShow = true;
        if (res.code == 200) {
          that.groupingForList = res.data;
          if (list == null || numGroup) {
            that.InstanceList = res.data;
            let show = localStorage.getItem("homeMessage");
            let arrLength = this.InstanceList.length;
            if (show == 4 && arrLength < 2) {
              localStorage.removeItem("homeMessage");
              this.gonggeqiehuan(1);
            } else if (show == 9 && arrLength < 5) {
              localStorage.removeItem("homeMessage");
              this.gonggeqiehuan(1);
            } else if (show == 16 && arrLength < 10) {
              localStorage.removeItem("homeMessage");
              this.gonggeqiehuan(1);
            } else if (show != null) {
              this.gonggeqiehuan(Number(show));
            }
          } else {
            that.InstanceList = list.instanceList;
          }
          for (let i = 0; i < that.InstanceList.length; i++) {
            that.InstanceList[i].endTime;
            let now = new Date();
            let targetDate = new Date(that.InstanceList[i].endTime); // 你的目标日期
            const seconds = Math.floor((targetDate - now) / 1000);
            that.InstanceList[i].countDown =
              that.InstanceList[i].expireSeconds * 1000;
            const days = Math.floor(seconds / (3600 * 24));
            that.InstanceList[i].remind = days;
            // 续费弹窗提示获取本地是否隐藏
            that.InstanceList[i].statusShow = true;
            if (Show !== null) {
              for (let j = 0; j < Show.length; j++) {
                if (that.InstanceList[i].id == Show[j].id) {
                  if (
                    (that.InstanceList[i].expireSeconds < 86400 &&
                      Show[j].expireSeconds > 86400) ||
                    (that.InstanceList[i].expireSeconds < 259200 &&
                      Show[j].expireSeconds > 259200)
                  ) {
                    that.InstanceList[i].statusShow = true;
                  } else {
                    that.InstanceList[i].statusShow = false;
                  }
                }
              }
            }
            // 倒计时显隐
            // 时间小于三天，满足分组续费条件，倒计时时间大于0，已经进入过了分组续费
            // 1-3天分组续费，时间大于1天，1天内分组续费，时间大于1天
            if (
              that.InstanceList[i].isAuthorized != 1 &&
              that.InstanceList[i].isGroupRenew &&
              that.InstanceList[i].expireSeconds < 259200 &&
              that.InstanceList[i].groupRenewActivityTime > 0 &&
              that.InstanceList[i].groupRenewActivityStartTime
            ) {
              if (
                (that.InstanceList[i].isDisplay != 0 &&
                  that.InstanceList[i].expireSeconds > 86400) ||
                (that.InstanceList[i].isDisplay == 0 &&
                  that.InstanceList[i].expireSeconds < 86400)
              ) {
              }
              // 倒计时对象
              this.countDownObj = that.InstanceList[i];
            }
          }
          if (res.data.length <= 0) {
            that.buyShow = true;
          } else {
            this.integer = "1";
          }
          this.delay = true;
          // that.$refs.van_swipe.swipeTo(0);
        } else if (res.code == 401) {
          that.buyShow = true;
          localStorage.removeItem("token");
          localStorage.removeItem("data");
        }
      });
    },
    // 获取活动提示
    async getactivityreminder() {
      let that = this;
      await activityreminder().then((res) => {
        if (res.code == 200) {
          if (this.applePushShow) {
            this.$refs.sonShow.ShowClick();
            this.SET_APPLEPUSHSHOW(false);
          } else {
            that.reminder = res.data;
            that.reminder_show = res.data.show;
          }
        }
      });
    },
    // 活动跳转
    remintz() {
      if (this.reminder.url) {
        location.href = this.reminder.url;
      } else {
        switch (this.reminder.activityType) {
          case 1:
            // 1.全民推广 2.激活码 3.邀请好友 4.购买设备 5.拼团活动 6.dnf手游试玩 7.qq群
            this.$router.push("/agencyCentre");
            break;
          case 2:
            this.$router.push("/activationconversion");
            break;
          case 3:
            this.$router.push("/invitefriends");
            break;
          case 4:
            let _vipCode = null;
            if (
              this.reminder.vipCode == "VIP1" ||
              this.reminder.vipCode == "VIP2" ||
              this.reminder.vipCode == "VIP3" ||
              this.reminder.vipCode == "VIP4"
            ) {
              _vipCode = this.reminder.vipCode.slice(-1) - 0;
            } else {
              _vipCode = this.reminder.vipCode;
            }
            this.$router.push({
              path: "/newcloudphone",
              query: { VIP: _vipCode },
            });
            break;
          case 5:
            this.$router.push("/newGroupBooking");
            break;
          case 6:
            this.$router.push("/attemptPlay");
            break;
          case 7:
            location.href =
              "http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=1HSqtfJflr_eqRXMIefHixSkleP90E9_&authKey=M%2BHGZYh8wi%2F3b32qLCGn94aGlzmXQByMg6Ff2I%2BMYnC1sAGnt%2F1UJOtUTis%2FpS2A&noverify=0&group_code=462925501";
            this.reminder_show = false;
            break;
          default:
            this.reminder_show = false;
        }
      }
    },
    GroupList(List) {
      this.InstanceList = List.instanceList;
    },
    countDownJump() {
      this.$router.push("/newGroupBooking");
    },
    CountDownClose() {
      this.SET_COUNTDOWNICONSHOW(false);
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  margin-bottom: 50px;
  // overflow-y: auto;
  .main {
    margin-top: -10px;
  }
}

.remincla {
  .webImageImgPopup {
    // width: 90%;
    background: none;
    overflow: hidden;
  }
  .remi_img {
    // max-width: calc(100vw - 32px);
    // max-height: calc(100vh - 92px);
    margin: 16px auto 16px auto;
    .webImageImg {
      width: 100%;
    }
    .remi_bt {
      background: #fff;
      padding: 16px;
      border-radius: 10px;
      .remi_btP1 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .remi_btP2::-webkit-scrollbar {
        width: 0; /* 宽度设为0 */
        height: 0; /* 高度设为0 */
      }
      .remi_btP2 {
        font-size: 14px;
        overflow: auto;
        max-height: 400px;
      }
    }
    img {
      border-radius: 10px 10px 10px 10px;
    }
    overflow: hidden;
  }
  .remi_guanbi {
    img {
      width: 28px;
      height: 28px;
    }
  }
}
.countDownJump {
  position: fixed;
  right: 0;
  bottom: 150px;
  width: 82px;
  height: 82px;
  background-image: url("../../assets/img/activity/newGroupBooking/countDownIcon-no.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .closeCountDown {
    position: absolute;
    left: 0;
    top: -10px;
    width: 20px;
    height: 20px;
  }
}
.countDownOpenJump {
  position: fixed;
  right: 0;
  bottom: 150px;
  width: 95px;
  height: 86px;
  background-image: url("../../assets/img/activity/newGroupBooking/countDownIcon-open.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .closeCountDown {
    position: absolute;
    left: 0;
    top: -10px;
    width: 20px;
    height: 20px;
  }
  .timeName {
    width: 82px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 3px;
    color: #ffe09b;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    letter-spacing: -1px;
  }
}
</style>