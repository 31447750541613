<template>
  <div class="mine">
    <div class="headmine">
      <div class="main">
        <img class="headLeft" src="../../assets/img/mineAmend/my.png" alt="" />
        <div class="headRight">
          <router-link to="/setting" class="mine_right">
            <img src="../../assets/img/mineAmend/headSet.png" alt="" />
          </router-link>
          <router-link to="/Messagecenter" class="mine_right_xiaoxi">
            <van-badge :content="count" max="99" v-if="count != 0">
              <img src="../../assets/img/mineAmend/headHews.png" alt="" />
            </van-badge>
            <img v-else src="../../assets/img/mineAmend/headHews.png" alt="" />
          </router-link>
        </div>
      </div>
      <div class="portrait">
        <div class="mine_left">
          <!-- <router-link to="/individual"> -->
          <img
            @click="individualCilck"
            :src="
              userinfo.avatar
                ? userinfo.avatar
                : require('../../assets/img/morentouxiang.png')
            "
            alt=""
          />
          <!-- </router-link> -->
        </div>
        <div class="mine_center">
          <div class="name">
            {{ userinfo.nickName ? userinfo.nickName : "傲晨云贵客" }}
          </div>
          <div class="uid">
            <div>ID: {{ userinfo.id ? userinfo.id : "00000001" }}</div>
            <img @click="issucopy" src="../../assets/icons/copy.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="lianxi_kefu">
      <div class="serviceTop">
        <img
          class="serviceIcon"
          src="../../assets/img/mineAmend/serviceIcon@2x.png"
          alt=""
        />
        <div class="serviceText">
          <p class="textp1">7x24小时在线客服</p>
          <p class="textp2">如果需帮助，请点我咨询</p>
        </div>
      </div>
      <button class="kefu_btn" @click="zaixiankefu">立即咨询</button>
    </div>
    <div class="gongneng">
      <div class="menuBox">
        <p class="Common">常用功能</p>
        <ul>
          <!-- <li>
          <img src="../../assets/img/mineAmend/uploadingIcon.png" alt="" />
          <p>文件上传</p>
        </li> -->
          <li>
            <router-link to="/Cloudsetting">
              <img src="../../assets/img/mineAmend/setIcon.png" alt="" />
              <p>云手机设置</p>
            </router-link>
          </li>
          <li class="lis2" v-if="invite">
            <router-link to="/invitefriends">
              <img src="../../assets/img/mineAmend/inviteIcon.png" alt="" />
              <p>邀请好友</p>
            </router-link>
          </li>
          <li class="lis3">
            <router-link to="/purchaserecord">
              <img src="../../assets/img/mineAmend/recordIcon.png" alt="" />
              <p>购买记录</p>
            </router-link>
          </li>
          <li>
            <router-link to="/activationconversion">
              <img src="../../assets/img/mineAmend/activateIcon.png" alt="" />
              <p>激活码</p>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/shareBrokerage">
              <img src="../../assets/img/mineAmend/promotionIcon.png" alt="" />
              <p>全民推广</p>
            </router-link>
          </li> -->
          <li @click="agencyCentreClick" style="position: relative">
            <img src="../../assets/img/mineAmend/promotionIcon.png" alt="" />
            <p>推广拿佣金</p>
            <img
              class="promotionHot"
              src="../../assets/img/mineAmend/promotionIconHot.png"
              alt=""
            />
          </li>
          <li>
            <router-link to="/homepagelicensing">
              <img src="../../assets/img/mineAmend/impowerIcon.png" alt="" />
              <p>设备授权</p>
            </router-link>
          </li>
          <li>
            <router-link to="/coupon">
              <img src="../../assets/img/mineAmend/couponIcon.png" alt="" />
              <p>我的优惠券</p>
            </router-link>
          </li>
          <li @click="convertiClick">
            <img src="../../assets/img/mineAmend/converti.png" alt="" />
            <p>兑换设备</p>
          </li>
          <li v-if="end">
            <router-link to="/grouphugebenefit">
              <img
                src="../../assets/img/mineAmend/groupBookingIcon.png"
                alt=""
              />
              <p>拼团钜惠</p>
            </router-link>
          </li>
          <li v-if="new_group_buyShow == '0'">
            <router-link to="/newGroupBooking">
              <img
                src="../../assets/img/mineAmend/groupBookingIcon.png"
                alt=""
              />
              <p>新春团购</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="addCrowd">
      <img
        class="crowdImg"
        src="../../assets/img/mineAmend/qqIcon.png"
        alt=""
      />
      <div class="crowdText">
        点击添加
        <a :href="qqGroupUrl">傲晨云手机玩家交流总群</a>
        ，24小时值班为您服务~
      </div>
    </div>
    <!-- 重命名 -->
    <van-dialog v-model="nameShow" :showConfirmButton="false" width="296px">
      <div class="TitleBox">
        <div class="deleteTitle">兑换设备</div>
        <img
          src="@/assets/img/homeAmend/x.png"
          alt=""
          @click="nameShow = false"
        />
      </div>
      <div class="fieldBox">
        <!-- <div :class="['wire', focus ? 'blue' : '']"></div> -->
        <van-field
          maxlength="12"
          v-model="convertValue"
          placeholder="请输入兑换码"
          class="hintText"
          @focus="obtain"
          @blur="focus = false"
        />
        <div :class="['wire', focus ? 'blue' : '']"></div>
      </div>
      <div class="deleteOperate">
        <button class="button deleteCancel" @click="nameShow = false">
          取消
        </button>
        <button class="button deleteAffirm" @click="nameAffirm">确认</button>
      </div>
    </van-dialog>
    <AochenFooter></AochenFooter>
    <!-- :overlay="false" -->
    <van-popup class="envelopePopup" v-model="show">
      <!-- <EsSvga
        :svgaImg="svgaImg"
        elid="files-add"
        :width="100"
        :height="100"
      ></EsSvga>  -->

      <div
        @click="openEnvelope"
        id="demoCanvas"
        style="width: 100%; height: 600px; overflow: hidden; margin: 0 auto"
      ></div>
      <img
        @click="closeClick"
        v-show="closeShow"
        class="closeEnvelope"
        src="../../assets/img/mineAmend/closeEnvelope.png"
        alt=""
      />
    </van-popup>
  </div>
</template>

<script>
import AochenFooter from "@/components/AochenFooter";
import {
  unread,
  applyRedemptionCode,
  sysConfigByConfigKey,
} from "@/api/index.js";
import EsSvga from "../../components/EsSvga.vue";
// import svgaImg from '../../assets/svg/op_1686118482.svga';
import SVGA from "svgaplayerweb";
import { Toast } from "vant";
export default {
  name: "AochenyunshoujiMine",
  components: {
    AochenFooter,
    EsSvga,
  },
  data() {
    return {
      userinfo: {},
      end: false,
      count: 0,
      invite: false,
      nameShow: false,
      convertValue: "",
      focus: false,
      show: false,
      datatemp: {},
      player: null,
      playerShow: false,
      closeShow: false,
      svgaImg: "/envelopeCartoon.svga",
      qqGroupUrl: "",
      new_group_buyShow: "1",
      // svgaImg: "/api/img/envelope.svga",
      // svgaImg:"https://photo.storage.vvvchat.com/assets/zj/op_1686118482.svga"
    };
  },

  mounted() {
    this.activityEnd();
    if (JSON.parse(localStorage.getItem("data"))) {
      this.userinfo = JSON.parse(localStorage.getItem("data"));
    } else {
      this.$router.push("/login");
    }
    sysConfigByConfigKey({ configKey: "h5_qqGroup" }).then((res) => {
      this.qqGroupUrl = res.data;
    });
    sysConfigByConfigKey({ configKey: "new_group_buy" }).then((res) => {
      this.new_group_buyShow = res.data;
    });
  },

  methods: {
    individualCilck() {
      // let envelopeLocal = JSON.parse(localStorage.getItem("envelopeLocal"));
      // if (!envelopeLocal) {
      //   this.show = true;
      //   // 调用方法
      //   this.$nextTick(function () {
      //     this.showSvg("/envelopeCartoon.svga");
      //   });
      // }else{
      //   this.$router.push("/individual")
      // }
      this.$router.push("/individual");
    },
    showSvg(url) {
      let self = this;
      //   //一定要使用$nextTick，等到页面加载完成再处理数据
      //   this.$nextTick(() => {
      //     //这里动态给ID，列表
      //     this.player = new SVGA.Player("#giftStyleAdd");
      //     var parser = new SVGA.Parser("#giftStyleAdd");
      //     this.player.loops=1;
      //     this.player.fillMode= "Backward"
      //     //这里使用动态加载的方式
      //     parser.load(url, (videoItem) => {
      //       this.widthDialog = String(videoItem.videoSize.width);
      //       this.player.setVideoItem(videoItem);
      //       this.player.startAnimation();
      //       this.player.setText('Hello, World!', 'ImageKey');
      //     });
      //   });
      self.player = new SVGA.Player("#demoCanvas");
      var parser = new SVGA.Parser("#demoCanvas");
      parser.load(url, function (videoItem) {
        self.player.loops = 1;
        self.player.clearsAfterStop = false;
        self.player.setVideoItem(videoItem);
        self.player.startAnimation();
        self.player.onFrame(function (i) {
          if (i == 1 && !self.playerShow) {
            self.player.pauseAnimation();
          }
          if (i == 59) {
            self.closeShow = true;
          }
        });
      });
    },
    openEnvelope() {
      if (!this.playerShow) {
        this.playerShow = true;
        this.player.startAnimation();
      }
    },
    closeClick() {
      this.show = false;
      localStorage.setItem("envelopeLocal", true);
    },
    // 复制
    issucopy() {
      this.copy(this.userinfo.id);
    },
    // 复制方法
    copy(value) {
      var aux = document.createElement("input");
      aux.setAttribute("value", value);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      Toast("复制成功！");
    },
    // 活动结束
    activityEnd() {
      unread().then((res) => {
        if (res.code == 200) {
          this.end = res.data.switch;
          this.invite = res.data.invite;
          this.count = res.data.count;
        }
      });
    },
    zaixiankefu() {
      // window.open("https://wpa1.qq.com/xYcPzLjt?_type=wpa&qidian=true");
      this.$router.push("/clientServe");
      // location.href = ;
    },
    convertiClick() {
      this.nameShow = true;
      this.convertValue = "";
    },
    obtain() {
      this.focus = true;
    },
    nameAffirm() {
      applyRedemptionCode({ redemptionCode: this.convertValue }).then((res) => {
        if (res.code == 200) {
          this.nameShow = false;
          Toast("兑换成功!");
        } else {
          Toast(res.msg);
        }
      });
    },
    agencyCentreClick() {
      this.$router.push("/agencyCentre");
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  border: none;
  background: none;
}
.wire {
  height: 1px;
  width: 85%;
  background-color: #f0ebeb;
  margin: 0 auto;
}
.blue {
  background-color: #558bff;
}
.TitleBox {
  position: relative;
  img {
    width: 16px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(-50%, -50%);
  }
}
.fieldBox {
  margin-top: 28px;
  /deep/ .van-field__body {
    font-size: 16px;
    height: 50px;
  }
}

.deleteTitle {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-top: 16px;
}
.hintText {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  // margin-top: 26px;
  padding: 0 24px;
}
.deleteOperate {
  margin: 38px auto 20px auto;
  .deleteCancel {
    width: 116px;
    height: 40px;
    background: #edeff3;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #9b9b9b;
    margin-right: 16px;
    line-height: 40px;
  }
  .deleteAffirm {
    width: 116px;
    height: 40px;
    background: #558bff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    line-height: 40px;
  }
  .machineCancel {
    width: 25%;
    height: 30px;
    background: #edeff3;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #9b9b9b;
    line-height: 30px;
  }
  .machineAffirm {
    width: 25%;
    height: 30px;
    background: #558bff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
    margin-left: 12px;
  }
  .gram {
    background: #aaacb5;
  }
}
.mine {
  padding-bottom: 40px;
  .headmine {
    background: url("../../assets/img/mineAmend/myHeadBack.png");
    width: 100%;
    height: calc(264px - 44px);
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    .main {
      display: flex;
      height: 44px;
      padding: 0 20px 0 16px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .headLeft {
        width: 40px;
        height: 24px;
      }
      .headRight {
        display: flex;
        img {
          width: 100%;
        }
        .mine_right_xiaoxi {
          width: 28px;
          height: 28px;
          /deep/ .van-badge--fixed {
            top: 6px;
            right: 5px;
          }
          /deep/ .van-badge {
            font-size: 10px;
            min-width: 12px;
          }
        }
        .mine_right {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }
    .portrait {
      display: flex;
      align-items: center;
      padding-left: 16px;
      height: 60px;
      margin-top: 24px;
      .mine_left {
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .mine_center {
        flex: 1;
        text-align: left;
        margin-left: 14px;
        color: #fff;
        .name {
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 10px;
        }
        .uid {
          font-size: 14px;
          color: #666666;
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-left: 4px;
          }
        }
      }
    }
  }

  .lianxi_kefu {
    // background: #fff;
    margin: 0 auto;
    width: calc(100% - 32px);
    height: 126px;
    display: flex;
    margin-top: -63px;
    // background: linear-gradient(154deg, #00c2ff 0%, #0066ff 100%);
    border-radius: 15px;
    flex-direction: column;
    background-image: url("../../assets/img/mineAmend/serviceBack.png");
    background-repeat: no-repeat;
    background-size: 100%;
    // justify-content: center;
    .serviceTop {
      display: flex;
      margin-left: 20px;
      margin-top: 8px;
      align-items: center;
      .serviceIcon {
        width: 57px;
        height: 53px;
        margin-right: 6px;
      }
      .serviceText {
        text-align: left;
        .textp1 {
          font-size: 17px;
          font-weight: 500;
          color: #fff;
          line-height: 0px;
        }
        .textp2 {
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          line-height: 0px;
          opacity: 0.8;
          margin-top: 12px;
        }
      }
    }
    .kefu_btn {
      width: calc(100% - 40px);
      height: 40px;
      background: #fff;
      border-radius: 20px 20px 20px 20px;
      border: 1px solid #fff;
      margin: 0 auto;
      font-size: 15px;
      font-weight: 400;
      color: #0066ff;
      // line-height: 22px;
      margin-top: 2px;
    }
  }
  .gongneng {
    width: calc(100% - 32px);
    margin: 0 auto;
    // height: 214px;
    margin-top: 10px;
    background: #fff;
    border-radius: 12px;
    .menuBox {
      padding: 16px 16px 16px 16px;
      box-sizing: border-box;
      .Common {
        text-align: left;
        line-height: 0px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
      }
      ul {
        text-align: left;
        margin-top: 17px;
        li {
          display: inline-block;
          width: 25%;
          text-align: center;
          img {
            width: 42px;
            height: 42px;
          }
          p {
            line-height: 13px;
            margin: 2px 0 16px 0;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
  .addCrowd {
    width: calc(100% - 32px);
    height: 56px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 12px;
    margin-bottom: 20px;
    text-align: left;
    .crowdImg {
      width: 22px;
      margin-right: 12px;
    }
    .crowdText {
      font-size: 15px;
      font-weight: 400;
      color: #000000;
      a {
        font-size: 15px;
        font-weight: 400;
        color: #0066ff;
        margin: 0 -3px;
      }
    }
  }
}
.envelopePopup {
  width: 100%;
  background-color: transparent;
  // height: 80%;
  // background-color: ;
  // overflow: hidden;
  text-align: center;
}
.closeEnvelope {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
#giftStyleAdd {
  width: 100%;
  height: 100%;
}
canvas {
  width: 100%;
  height: 100%;
}
.promotionHot {
  position: absolute;
  top: 0;
  right: 8px;
  width: 32px !important;
  height: 16px !important;
}
</style>