<template>
  <div class="AC_BgsSdk" @click.stop>
    <!-- 全屏标签 page-only否仅允许全屏模式，
    exit-on-click-wrapper是否在点击全屏包装器时退出全屏模
    fullscreen-class全屏模式下的类名
    -->
    <!-- <fullscreen
      :page-only="true"
      v-model="fullscreen"
      :exit-on-click-wrapper="false"
      fullscreen-class="big-view bg-white"
      class="full_qp"
    > -->
    <div
      :class="[
        'phoneBox_hf',
        Float ? 'phoneBox_qp' : '',
        rightMenuShow ? '' : 'rightMenu',
      ]"
    >
      <!-- <div
          class="phoneBox"
          id="phoneBox"
          style="
            background-color: grey;
            overflow: hidden;
            width: 100%;
            height: 100%;
            text-align: center;
          "
          :style="{ background: Float ? '#000' : '#000' }"
        ></div> -->
      <div
        id="phoneBox"
        style="width: 100%; height: 100%; overflow: hidden; background: #000"
      ></div>
      <div class="header" v-if="!Float">
        <div class="headerLedt">
          <div
            class="equipment"
            @click="(equipmentShow = true), (fullStatus = 0)"
          >
            <div>
              <div class="CloudIconBox">
                <img
                  v-if="
                    (phone.vipCode == 'VIP1') &
                    (phone.deviceVersionCode == 'V8')
                  "
                  src="@/assets/icons/biaozhunanzhuo8.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP1') &
                    (phone.deviceVersionCode == 'V10')
                  "
                  src="@/assets/icons/biaozhunanzhuo10.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP2') &
                    (phone.deviceVersionCode == 'V8')
                  "
                  src="@/assets/icons/changwananzhuo8.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP2') &
                    (phone.deviceVersionCode == 'V10')
                  "
                  src="@/assets/icons/changwananzhuo10.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP3') &
                    (phone.deviceVersionCode == 'V8')
                  "
                  src="@/assets/icons/aorananzhuo8.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP3') &
                    (phone.deviceVersionCode == 'V10')
                  "
                  src="@/assets/icons/aorananzhuo10.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP4') &
                    (phone.deviceVersionCode == 'V8')
                  "
                  src="@/assets/icons/zhizunanzhuo8.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP4') &
                    (phone.deviceVersionCode == 'V10')
                  "
                  src="@/assets/icons/zhizunanzhuo10.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP1') &
                    (phone.deviceVersionCode == 'V12')
                  "
                  src="@/assets/icons/biaozhunanzhuo12.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP2') &
                    (phone.deviceVersionCode == 'V12')
                  "
                  src="@/assets/icons/changwananzhuo12.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP3') &
                    (phone.deviceVersionCode == 'V12')
                  "
                  src="@/assets/icons/aorananzhuo12.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP4') &
                    (phone.deviceVersionCode == 'V12')
                  "
                  src="@/assets/icons/zhizunanzhuo12.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode != 'VIP1') &
                    (phone.vipCode != 'VIP2') &
                    (phone.vipCode != 'VIP3') &
                    (phone.vipCode != 'VIP4')
                  "
                  src="@/assets/icons/dingzhianzhuo.png"
                  alt=""
                />
              </div>
              <div>
                {{ phone.instanceName }}
              </div>
            </div>
            <div>
              <img
                class="bottomImg"
                src="../assets/img/homeAmend/headname.png"
                alt=""
              />
            </div>
          </div>
          <div class="qingxidu" @click="pixelShow = true">
            <div>
              <!-- {{ pixelName }} -->
              <span v-if="value1 == 0">流畅</span>
              <span v-if="value1 == 1">标清</span>
              <span v-if="value1 == 2">高清</span>
            </div>
            <img
              v-show="pixelShow"
              src="../assets/img/homeAmend/pixelbottom.png"
              alt=""
            />
            <img
              v-show="!pixelShow"
              src="../assets/img/homeAmend/pixelbottom.png"
              alt=""
            />
          </div>
          <div class="header_zl">
            <div
              :class="[
                netDelay < 50 ? 'btn_lv' : '',
                netDelay >= 50 && netDelay < 200 ? 'btn_huang' : '',
                netDelay >= 200 ? 'btn_hong' : '',
              ]"
            >
              <img
                v-if="netDelay < 50"
                src="../assets/img/homeAmend/signalGood.png"
                alt=""
              />
              <img
                v-else-if="netDelay >= 50 && netDelay < 200"
                src="../assets/img/homeAmend/signalNormal.png"
                alt=""
              />
              <img
                v-else-if="netDelay >= 200"
                src="../assets/img/homeAmend/signalBad.png"
                alt=""
              />
              <span>{{ netDelay }}</span>
              ms
            </div>
          </div>
        </div>
        <div class="headerRight" @click="rightMenuShow = !rightMenuShow">
          <img
            v-show="rightMenuShow"
            src="../assets/img/homeAmend/spread.png"
            alt=""
          />
          <img
            v-show="!rightMenuShow"
            src="../assets/img/homeAmend/packUp.png"
            alt=""
          />
        </div>
      </div>

      <div
        class="footer"
        :style="{ width: rightMenuShow ? '90%' : '100%' }"
        v-if="!Float"
      >
        <div>
          <img
            @click="ysjclick('home')"
            src="../assets/img/homeAmend/cut.png"
            alt=""
          />
        </div>
        <div>
          <img
            @click="ysjclick('zhuomian')"
            src="../assets/img/homeAmend/desktop.png"
            alt=""
          />
        </div>
        <div>
          <img
            @click="ysjclick('fanhui')"
            src="../assets/img/homeAmend/getBack.png"
            alt=""
          />
        </div>
      </div>
      <div class="rightKey" v-if="!Float && rightMenuShow">
        <div v-for="item in menuResult" :key="item.text">
          <div
            class="last"
            v-if="item.checked"
            @click="menuRorClick(item.text)"
          >
            <img :src="item.src" alt="" />
            <div>{{ item.text }}</div>
          </div>
        </div>
        <div class="last" @click="stopGame">
          <img src="../assets/img/homeAmend/quitImg.png" alt="" />
          <div>退出</div>
        </div>
      </div>
      <van-dialog
        v-model="show"
        :showConfirmButton="false"
        :showCancelButton="false"
        :close-on-click-overlay="true"
        className="dialogName"
      >
        <div class="jqb">
          <!-- <span>剪切板（{{ jqbList.length }}/8）</span>
            <button class="btn">全部清空</button> -->
          <div class="jqbLeft">
            <div class="jqbName">剪切板</div>
            <div class="jqbBum">({{ jqbList.length }}/8)</div>
          </div>
          <div class="jqbRight" @click="allEmpty">
            <img src="../assets/img/homeAmend/empty.png" alt="" />
            <button class="btn">全部清空</button>
          </div>
        </div>
        <van-swipe-cell
          v-for="(item, index) in jqbList"
          :key="index"
          @open="jqbOpen(index)"
          @close="jqbClose"
        >
          <van-field
            v-model="item.jqb_value"
            :placeholder="item.placeholder"
            :class="item.jqb_value ? 'van_field_colorbai' : ''"
          />
          <button
            class="jqb_btncopy"
            @click.stop="item.jqb_value ? jqb_copy(item.jqb_value, true) : ''"
          >
            <div v-if="signStatus != index">
              <img
                v-if="item.jqb_value"
                src="../assets/img/homeAmend/activate.png"
                alt=""
                class="actiImg"
              />
              <img
                class="actiImg"
                v-else
                src="../assets/img/homeAmend/default.png"
                alt=""
              />
            </div>
          </button>
          <template #right>
            <button class="jqb_delcopy" @click="jqb_delete(index)">删除</button>
            <button class="jqb_clearcopy" @click="jqb_clear(index)">
              清空
            </button>
          </template>
        </van-swipe-cell>
        <button
          v-if="jqbList.length < 8"
          class="jqb_tianjia"
          @click="jqb_tianjia"
        >
          <img src="../assets/icons/jqb_bianzu.png" alt="" />
        </button>
      </van-dialog>
    </div>
    <!-- fullscreen &&  -->
    <Floating
      v-if="Float"
      @xuanfuchuan="xuanfuchuan"
      :netDelay="netDelay"
      :floatPhone="floatPhone"
    />
    <van-popup v-model="popupshow">
      <div class="kongzhi">
        <div class="topQuit">
          <div class="nameLeft" @click="fullFacility">
            <div class="nameLeftBox">
              <div class="CloudIconBox">
                <img
                  v-if="
                    (phone.vipCode == 'VIP1') &
                    (phone.deviceVersionCode == 'V8')
                  "
                  src="@/assets/icons/biaozhunanzhuo8.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP1') &
                    (phone.deviceVersionCode == 'V10')
                  "
                  src="@/assets/icons/biaozhunanzhuo10.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP2') &
                    (phone.deviceVersionCode == 'V8')
                  "
                  src="@/assets/icons/changwananzhuo8.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP2') &
                    (phone.deviceVersionCode == 'V10')
                  "
                  src="@/assets/icons/changwananzhuo10.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP3') &
                    (phone.deviceVersionCode == 'V8')
                  "
                  src="@/assets/icons/aorananzhuo8.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP3') &
                    (phone.deviceVersionCode == 'V10')
                  "
                  src="@/assets/icons/aorananzhuo10.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP4') &
                    (phone.deviceVersionCode == 'V8')
                  "
                  src="@/assets/icons/zhizunanzhuo8.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP4') &
                    (phone.deviceVersionCode == 'V10')
                  "
                  src="@/assets/icons/zhizunanzhuo10.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP1') &
                    (phone.deviceVersionCode == 'V12')
                  "
                  src="@/assets/icons/biaozhunanzhuo12.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP2') &
                    (phone.deviceVersionCode == 'V12')
                  "
                  src="@/assets/icons/changwananzhuo12.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP3') &
                    (phone.deviceVersionCode == 'V12')
                  "
                  src="@/assets/icons/aorananzhuo12.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode == 'VIP4') &
                    (phone.deviceVersionCode == 'V12')
                  "
                  src="@/assets/icons/zhizunanzhuo12.png"
                  alt=""
                />
                <img
                  v-if="
                    (phone.vipCode != 'VIP1') &
                    (phone.vipCode != 'VIP2') &
                    (phone.vipCode != 'VIP3') &
                    (phone.vipCode != 'VIP4')
                  "
                  src="@/assets/icons/dingzhianzhuo.png"
                  alt=""
                />
              </div>
              <div>
                {{ phone.instanceName }}
              </div>
            </div>
            <div>
              <img
                class="bottomImg"
                src="../assets/img/homeAmend/headname.png"
                alt=""
              />
            </div>
          </div>
          <div class="quitBox" @click="screenSize(false), (popupshow = false)">
            <img src="../assets/img/homeAmend/quitFull.png" alt="" />
            <div>退出全屏</div>
          </div>
        </div>
        <div class="secondBox">
          <div class="networkTop">
            <div class="networkName" v-if="netDelay < 50">网络良好</div>
            <div class="networkName" v-if="netDelay >= 50 && netDelay < 200">
              网络正常
            </div>
            <div class="networkName" v-if="netDelay >= 200">网络较差</div>
            <div class="header_zl">
              <div
                :class="[
                  netDelay < 50 ? 'btn_lv' : '',
                  netDelay >= 50 && netDelay < 200 ? 'btn_huang' : '',
                  netDelay >= 200 ? 'btn_hong' : '',
                ]"
              >
                <img
                  v-if="netDelay < 50"
                  src="../assets/img/homeAmend/signalGood.png"
                  alt=""
                />
                <img
                  v-else-if="netDelay >= 50 && netDelay < 200"
                  src="../assets/img/homeAmend/signalNormal.png"
                  alt=""
                />
                <img
                  v-else-if="netDelay >= 200"
                  src="../assets/img/homeAmend/signalBad.png"
                  alt=""
                />
                <span>{{ netDelay }}</span>
                ms
              </div>
            </div>
          </div>
          <div class="huazhi">
            <button
              :class="['flo_btn', value1 == 0 ? 'act_btn' : '']"
              @click="qiehuan_qxd(0)"
            >
              流畅
            </button>
            <button
              :class="['flo_btn', value1 == 1 ? 'act_btn' : '']"
              @click="qiehuan_qxd(1)"
            >
              标清
            </button>
            <button
              :class="['flo_btn', value1 == 2 ? 'act_btn' : '']"
              @click="qiehuan_qxd(2)"
            >
              高清
            </button>
          </div>
        </div>
        <div class="functionList">
          <div class="publicBox" @click="jianqieban">
            <img src="../assets/img/homeAmend/shearPlateImg.png" alt="" />
            <div>剪切板</div>
          </div>
          <div class="publicBox" @click="pageService">
            <img src="../assets/img/homeAmend/serviceImg.png" alt="" />
            <div>客服</div>
          </div>
          <div class="publicBox" @click="xushi">
            <img src="../assets/img/homeAmend/continueImg.png" alt="" />
            <div>续时</div>
          </div>
          <div class="publicBox" @click="(allShow = true), (popupshow = false)">
            <img src="../assets/img/homeAmend/restartImg.png" alt="" />
            <div>重启</div>
          </div>
          <div class="publicBox red" @click="stopGame">
            <img src="../assets/img/homeAmend/quitImg.png" alt="" />
            <div>退出</div>
          </div>
        </div>
      </div>
      <div class="tuichuysj">
        <div class="common">
          <img
            @click="ysjclick('home')"
            src="../assets/img/homeAmend/fullCut.png"
            alt=""
          />
          <div>切换</div>
        </div>
        <div class="common">
          <img
            @click="ysjclick('zhuomian')"
            src="../assets/img/homeAmend/fullDesktop.png"
            alt=""
          />
          <div>桌面</div>
        </div>
        <div class="common">
          <img
            @click="ysjclick('fanhui')"
            src="../assets/img/homeAmend/fullReturn.png"
            alt=""
          />
          <div>返回</div>
        </div>
      </div>
    </van-popup>
    <!-- 清晰度 -->
    <van-popup
      v-model="pixelShow"
      overlay-class="custom-overlay"
      class="gonggexz"
      position="top left"
    >
      <div
        class="gonggeqh"
        v-for="item in option1"
        :key="item.value"
        @click="qiehuan_qxd(item.value)"
        :style="{ color: value1 == item.value ? '#0066FF' : '#fff' }"
      >
        <span>{{ item.text }}</span>
      </div>
    </van-popup>
    <!-- 设备 -->
    <van-popup
      v-model="equipmentShow"
      :overlay-class="fullStatus == 1 ? '' : 'custom-overlay'"
      :class="['equPopup', fullStatus == 1 ? 'fullLocation' : 'keyLocation']"
      position="top left"
    >
      <div
        class="equList"
        v-for="item in headAllList"
        :key="item.id"
        @click="equipmentSelect(item)"
        :style="{ background: phone.id == item.id ? '#2E2E37' : '' }"
      >
        <div class="CloudIconBox">
          <img
            v-if="(item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V8')"
            src="@/assets/icons/biaozhunanzhuo8.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V10')"
            src="@/assets/icons/biaozhunanzhuo10.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V8')"
            src="@/assets/icons/changwananzhuo8.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V10')"
            src="@/assets/icons/changwananzhuo10.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V8')"
            src="@/assets/icons/aorananzhuo8.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V10')"
            src="@/assets/icons/aorananzhuo10.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V8')"
            src="@/assets/icons/zhizunanzhuo8.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V10')"
            src="@/assets/icons/zhizunanzhuo10.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V12')"
            src="@/assets/icons/biaozhunanzhuo12.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V12')"
            src="@/assets/icons/changwananzhuo12.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V12')"
            src="@/assets/icons/aorananzhuo12.png"
            alt=""
          />
          <img
            v-if="(item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V12')"
            src="@/assets/icons/zhizunanzhuo12.png"
            alt=""
          />
          <img
            v-if="
              (item.vipCode != 'VIP1') &
              (item.vipCode != 'VIP2') &
              (item.vipCode != 'VIP3') &
              (item.vipCode != 'VIP4')
            "
            src="@/assets/icons/dingzhianzhuo.png"
            alt=""
          />
        </div>
        <div class="quiName">{{ item.instanceName }}</div>
      </div>
    </van-popup>
    <!-- 重启 -->
    <van-dialog v-model="allShow" :showConfirmButton="false" width="296px">
      <div class="TitleBox">
        <div class="deleteTitle">重启云手机</div>
        <img
          src="@/assets/img/homeAmend/x.png"
          alt=""
          @click="allShow = false"
        />
      </div>
      <div class="hintText">是否确定重启云手机？重启期间无法进行其他操作！</div>
      <div class="deleteOperate">
        <button class="button deleteCancel" @click="allShow = false">
          取消
        </button>
        <button class="button deleteAffirm" @click="allAffirm">确认</button>
      </div>
    </van-dialog>
    <!-- </fullscreen> -->
    <dialogHint
      :dialogShow="dialogShow"
      :dialogTitle="dialogTitle"
      @dialogClic="confirms"
    >
      <template v-slot:content>{{ dialogName }}</template>
    </dialogHint>
  </div>
</template>

<script >
import { Toast } from "vant";
import {
  serverToken,
  reportPage,
  instancelist,
  commandreboot,
} from "../api/index.js";
import Floating from "./Floating.vue";
import dialogHint from "@/components/publicModule/dialogHint.vue";
export default {
  name: "AochenyunshoujiNzSdk",
  components: {
    Floating,
    dialogHint,
  },
  data() {
    return {
      uuid: "test123",
      ACysj_BgsSdk: false,
      show: false,
      Float: false,
      popupshow: false,
      // fullscreen: false,
      phone: {},
      jqbList: [
        {
          jqb_value: "",
          placeholder: "按钮处进行左滑调出删除和清空按…",
        },
        {
          jqb_value: "",
          placeholder: "内容粘贴到此处后，点亮复制图标…",
        },
        {
          jqb_value: "",
          placeholder: "内容粘贴到此处后，点亮复制图标…",
        },
      ],
      netDelay: 0,
      value1: 2,
      option1: [
        { text: "流畅", value: 0 },
        { text: "标清", value: 1 },
        { text: "高清", value: 2 },
      ],
      zdqplist: [],
      definition: [],
      deviceInfo: null,
      statusShow: true,
      WebRtcShow: true,
      loading: true,
      useWcs: false,
      rightMenuShow: true,

      pixelShow: false,
      headAllList: [],
      equipmentShow: false,
      allShow: false,
      signStatus: -1,
      fullStatus: -1,
      clipboardContent: "",
      Timeoutr: null,
      floatPhone: {},
      voinitData: {},
      vostartData: {},
      dialogShow: false,
      dialogTitle: "提示",
      dialogName: "",
      sdkIns: null,
      menuResult: [],
    };
  },
  // watch: {
  //   phone: {
  //     handler(newVal) {
  //       console.log("Value changed:", newVal);
  //     },
  //     immediate: true,
  //   },
  // },

  mounted() {
    let _menuResult = JSON.parse(localStorage.getItem("menuResult"));
    if (_menuResult) {
      this.menuResult = _menuResult;
    } else {
      this.menuResult = [
        {
          checked: true,
          text: "全屏",
          src: require("@/assets/img/homeAmend/fullScreenImg.png"),
        },
        {
          checked: true,
          text: "剪切板",
          src: require("@/assets/img/homeAmend/shearPlateImg.png"),
        },
        {
          checked: true,
          text: "客服",
          src: require("@/assets/img/homeAmend/serviceImg.png"),
        },
        {
          checked: true,
          text: "续时",
          src: require("@/assets/img/homeAmend/continueImg.png"),
        },
        {
          checked: true,
          text: "重启",
          src: require("@/assets/img/homeAmend/restartImg.png"),
        },
      ];
    }
    let jqb = JSON.parse(sessionStorage.getItem("jqbList"));
    if (jqb) {
      this.jqbList = jqb;
    }
    this.getserverToken();
    this.zdqplist = JSON.parse(localStorage.getItem("zdqplist")) || [];
    this.definition = JSON.parse(localStorage.getItem("definition")) || [];
    // 自定义加载图标
    // Toast.loading({
    //   message: "加载中...",
    //   forbidClick: true,
    //   loadingType: "spinner",
    // });
    this.iosModel();
    this.gitTinstancelist();
    let _fastCopy = JSON.parse(localStorage.getItem("fastCopy"));
    if (_fastCopy || _fastCopy == null) {
      this.handleAddListener();
    }
    this.qpysj();
    // 监听页面不可见
    let cutQuit = JSON.parse(localStorage.getItem("cutQuit"));
    if (cutQuit == null || cutQuit) {
      document.addEventListener("visibilitychange", this.visibility);
    }
  },
  destroyed() {
    this.stopGame_();
    let _fastCopy = JSON.parse(localStorage.getItem("fastCopy"));
    if (_fastCopy || _fastCopy == null) {
      this.handleremoveListener();
    }
    document.removeEventListener("visibilitychange", this.visibility);
  },
  methods: {
    visibility() {
      let that = this;
      if (document.hidden) {
        console.log("H5已切换到后台或手机息屏");
        that.stopGame();
      } else {
        console.log("H5已切换到网页");
      }
    },
    confirms(msg) {
      this.dialogShow = msg;
      this.stopGame();
    },
    gethiddenStatus() {
      var isHidden = document.hidden;
      if (isHidden) {
        // 失去焦点
      } else {
        // 未失去焦点
        this.Timeoutr = setTimeout(() => {
          let _fastCopy = JSON.parse(localStorage.getItem("fastCopy"));
          if (_fastCopy || _fastCopy == null) {
            this.getClipboardContent();
          }
        }, 1000);
      }
    },
    // 开启
    handleAddListener() {
      document.addEventListener("visibilitychange", this.gethiddenStatus, true);
    },
    // 手动销毁
    handleremoveListener() {
      document.removeEventListener(
        "visibilitychange",
        this.gethiddenStatus,
        true
      );
    },
    // 自动复制实体手机剪切板
    getClipboardContent() {
      if (navigator.clipboard) {
        navigator.clipboard
          .readText()
          .then((text) => {
            this.clipboardContent = text;
            this.jqb_copy(text, false);
          })
          .catch((err) => {
            console.error("无法访问剪贴板：" + err);
          });
      }
    },
    // 查询设备
    gitTinstancelist() {
      instancelist().then((res) => {
        if (res.code == 200) {
          this.headAllList = res.data;
        }
      });
    },
    // 跳转客服
    pageService() {
      // location.href = "https://wpa1.qq.com/xYcPzLjt?_type=wpa&qidian=true";
      this.$router.push("/clientServe");
    },
    // 选择设备
    equipmentSelect(e) {
      if (e.channelCode === this.phone.channelCode) {
        this.equipmentShow = false;
        // 停止云手机
        this.sdkIns.destroy();
        // 启动云手机
        this.getserverToken(e);
      } else {
        localStorage.setItem("em", JSON.stringify(e));
        switch (e.channelCode) {
          case "baidu":
            this.$router.push({
              params: {
                e,
              },
              name: "BgsSdk",
            });
            break;
          case "huoshan":
            this.$router.push({
              params: {
                e,
              },
              name: "VeSdk",
            });
            break;
          case "intelligent":
            this.$router.push({
              params: {
                e,
              },
              name: "NzSdk",
            });
            break;
          default:
            console.log("0");
        }
      }
    },

    //续时
    xushi() {
      // this.stopGame_();
      sessionStorage.setItem("renewal", JSON.stringify(this.phone));
      this.$router.push({
        path: "/renewaltime",
      });
    },

    // 重启确认
    allAffirm() {
      this.allShow = false;
      let data = {
        instanceCodes: [this.phone.instanceCode],
      };
      commandreboot(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在一分钟内重启，期间无法操作！");
          this.stopGame();
        } else {
          Toast(res.msg);
        }
      });
    },
    fullFacility() {
      this.fullStatus = 1;
      this.popupshow = false;
      this.equipmentShow = true;
    },

    // 获取ios系统型号
    iosModel() {
      const userAgent = navigator.userAgent;
      const iosVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(userAgent);
      if (
        iosVersion &&
        iosVersion[1] + "." + iosVersion[2] >= "16.4" &&
        iosVersion[1] + "." + iosVersion[2] <= "16.6"
      ) {
        this.useWcs = true;
      }
    },
    // 启动前请求
    async getserverToken(e) {
      let that = this;
      Toast.loading({
        message: "载入中...",
        duration: 1000, // 持续展示 toast
        forbidClick: true,
        loadingType: "spinner",
      });
      let em = JSON.parse(localStorage.getItem("em"));
      let userdata = JSON.parse(localStorage.getItem("data"));
      that.phone = e || that.$route.params.e || em;
      that.floatPhone = e || that.$route.params.e || em;
      // this.phone = e || em;
      // this.phone = e;
      let data = {
        instanceCode: that.phone.instanceCode,
      };
      let resData = {};
      await serverToken(data).then((res) => {
        if (res.code == 200) {
          resData = res.data;
        } else {
          if (res.msg != null || res.msg != "") {
            this.dialogShow = true;
            this.dialogName = res.msg;
          } else {
            this.dialogName = "服务器异常，请稍后重试";
          }
        }
      });

      if (that.ACysj_BgsSdk == false) {
        that.ACysj_BgsSdk = true;
        reportPage({
          pageName: "打开云手机页面",
          pageCode: "phone",
          pageParam: that.phone.instanceCode,
        }).then((res) => {});
        function start() {
          // let _streamType = {};
          // if (that.useWCS) {
          //   _streamType = { streamType: 2 };
          // }
          that.sdkIns = new NzCp();
          let param = {
            userId: resData.hsServerTokenVo.uuid || userdata.uuid,
            mountId: "phoneBox",
            instanceNo: that.phone.instanceCode,
            streamType: 2,
            // ..._streamType,
          };
          let callbacks = {
            onInitFail: function (code) {
              console.log("初始化失败:" + code);
            },
            onInitSuccess: function () {
              console.log("初始化成功");
            },
            onStartFail: function (code) {
              console.log("链接失败:" + code);
            },
            onStartSuccess: function () {
              console.log("链接成功");
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              document.body.style.overflow = "hidden";
              that.ACysj_BgsSdk = false;
              that.rightMenuShow = true;
              that.qiehuan_qxd1();
              let _fastCopy = JSON.parse(localStorage.getItem("fastCopy"));
              if (_fastCopy || _fastCopy == null) {
                that.getClipboardContent();
              }
            },
            onError: function (code) {
              that.ACysj_BgsSdk = false;
              if (code == 60309009) {
                that.dialogName = "云手机已被其它客户端控制";
              } else if (code == 60309014) {
                that.dialogName = "长时间未操作,已退出云手机";
              } else {
                that.dialogName = "云手机连接超时，请重新连接!错误码：" + code;
              }
              that.dialogShow = true;
              console.log("报错了:" + code);
            },
            onPlayInfo: function (info) {
              that.netDelay = info.fps <= 500 ? info.fps : 500;
            },
          };
          let initRet = that.sdkIns.init(param, callbacks);
          if (!initRet) {
            console.log("初始化失败");
            return;
          }
        }
        start();
        that.sdkIns.start(
          resData.hsServerTokenVo.ak,
          resData.hsServerTokenVo.sk
        );
        // await serverToken(data).then((res) => {
        //   if (res.code == 200) {
        //     that.sdkIns.start(
        //       res.data.hsServerTokenVo.ak,
        //       res.data.hsServerTokenVo.sk
        //     );
        //   } else {
        //     if (res.msg != null || res.msg != "") {
        //       this.dialogShow = true;
        //       this.dialogName = res.msg;
        //     }else{
        //       this.dialogName = "服务器异常，请稍后重试";
        //     }
        //   }
        // });
      }
    },
    // 是否全屏按钮
    screenSize(e) {
      this.Float = e;
      this.phone.Float = this.Float;
      for (let i = 0; i < this.zdqplist.length; i++) {
        if (this.zdqplist[i].id == this.phone.id) {
          this.zdqplist.splice(i, 1);
        }
      }
      this.zdqplist.push(this.phone);
      localStorage.setItem("zdqplist", JSON.stringify(this.zdqplist));
      this.$nextTick(function () {
        this.sdkIns.nzSdkIns._resizeShowVideo();
      });
    },
    // 是否全屏启动云手机
    qpysj() {
      let zdqp = JSON.parse(localStorage.getItem("zdqplist"));
      if (zdqp) {
        for (let i = 0; i < zdqp.length; i++) {
          if (this.phone.id == zdqp[i].id) {
            this.Float = zdqp[i].Float;
          }
        }
      }
    },
    // 启动云手机
    startGame(deviceInfo) {
      let that = this;
      if (this.$common.isAppleWebKit()) {
        this.WebRtcShow = false;
      }
      const params = {
        deviceInfo: deviceInfo,
        viewId: "phoneBox",
        isWebRtc: this.WebRtcShow,
        // useWCS: this.useWcs,
        uuid: "205",
        width: 432,
        height: 768,
        bitrate: 2048,
        callbacks: {
          // 每秒触发一次，回调当前运行信息
          onRunInformation: (type, info) => {
            that.netDelay = info.netDelay <= 500 ? info.netDelay : 500;
          },
          onInit: (code, msg) => {
            // console.log("onInit:", code, msg);
          },
          // 连接成功
          onConnectSuccess: () => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            document.body.style.overflow = "hidden";
            this.ACysj_BgsSdk = false;
            this.rightMenuShow = true;
            this.qiehuan_qxd1();
            let _fastCopy = JSON.parse(localStorage.getItem("fastCopy"));
            if (_fastCopy || _fastCopy == null) {
              this.getClipboardContent();
            }
            // Toast.clear();
          },
          onRenderedFirstFrame: () => {
            // setTimeout(() => {
            redfinger.resizePhone();
            // }, 1000);
          },
          // 链接失败
          onConnectFail: (code) => {
            // Toast.clear();
            console.log("onConnectFail:", code, new Date());
          },
          // 播放异常
          onErrorMessage: (code) => {
            // Toast.clear();
            this.ACysj_BgsSdk = false;
            if (code == 196613) {
              this.dialogName = "云手机已被其它客户端操作-2分钟后重新连接";
            } else if (code == 196614) {
              this.dialogName = "长时间未操作,已退出云手机";
            } else if (code == 196626) {
              this.dialogName = "云手机已到期!";
            } else {
              this.dialogName = "云手机连接超时，请重新连接!错误码：" + code;
            }
            this.dialogShow = true;
          },
        },
      };
      let initRet = redfingerV2.init(params);
      if (initRet) {
        redfingerV2.start();
      }
    },
    // 停止云手机
    stopGame() {
      document.body.style.overflow = "auto";
      sessionStorage.removeItem("jqbList");
      sessionStorage.setItem("jqbList", JSON.stringify(this.jqbList));
      this.sdkIns.destroy();
      let that = this;
      that.popupshow = false;
      that.Float = false;
      this.rightMenuShow = false;
      // 恢复小屏
      // that.fullscreen = false;
      this.$router.push({
        path: "/",
      });
    },
    // 续时停止云手机
    stopGame_() {
      let that = this;
      document.body.style.overflow = "auto";
      sessionStorage.removeItem("jqbList");
      sessionStorage.setItem("jqbList", JSON.stringify(this.jqbList));
      this.sdkIns.destroy();
      that.popupshow = false;
      that.Float = false;
      this.rightMenuShow = false;
      // 恢复小屏
      // that.fullscreen = false;
    },

    // 底部操作事件
    ysjclick(e) {
      this.popupshow = false;
      if (e == "zhuomian") {
        this.sdkIns.home();
      }
      if (e == "home") {
        this.sdkIns.menu();
      }
      if (e == "fanhui") {
        this.sdkIns.back();
      }
    },
    jianqieban() {
      this.popupshow = false;
      this.show = true;
    },
    // 点击复制
    jqb_copy(item, Boolean) {
      this.sdkIns.sendInClipper(item);
      if (Boolean) {
        Toast("已复制到云手机中");
      }
    },
    // 全部清空
    allEmpty() {
      for (let i = 0; i < this.jqbList.length; i++) {
        this.jqbList[i].jqb_value = "";
      }
    },
    jqbOpen(e) {
      this.signStatus = e;
    },
    jqbClose() {
      this.signStatus = -1;
    },
    // 点击添加一个
    jqb_tianjia() {
      if (this.jqbList.length < 8) {
        this.jqbList.push({
          jqb_value: "",
          placeholder: "内容粘贴到此处后，点亮复制图标…",
        });
      }
    },
    // 点击删除一个
    jqb_delete(del_index) {
      this.jqbList.splice(del_index, 1);
    },
    // 点击清空目标内容
    jqb_clear(clear_index) {
      this.jqbList[clear_index].jqb_value = "";
    },
    // 切换清晰度
    qiehuan_qxd(e) {
      let _wholePixel = JSON.parse(localStorage.getItem("wholePixel"));
      if (_wholePixel != e) {
        localStorage.removeItem("wholePixel");
      }
      this.popupshow = false;
      this.pixelShow = false;
      this.value1 = e;
      this.phone.value1 = this.value1;
      for (let i = 0; i < this.definition.length; i++) {
        if (this.definition[i].id == this.phone.id) {
          this.definition.splice(i, 1);
        }
      }
      this.definition.push(this.phone);
      localStorage.setItem("definition", JSON.stringify(this.definition));
      if (e == 1) {
        this.sdkIns.setStreamProfile({
          width: 576,
          height: 1024,
          fps: 30,
          bitrate: 2048,
        });
      } else if (e == 2) {
        this.sdkIns.setStreamProfile({
          width: 720,
          height: 1280,
          fps: 30,
          bitrate: 4096,
        });
      } else {
        this.sdkIns.setStreamProfile({
          width: 432,
          height: 768,
          fps: 20,
          bitrate: 1024,
        });
      }
    },
    // 进入云手机触发换清晰度（取消e参数）
    qiehuan_qxd1() {
      let _wholePixel = JSON.parse(localStorage.getItem("wholePixel"));
      if ((_wholePixel && _wholePixel != -1) || _wholePixel == 0) {
        this.phone.value1 = _wholePixel;
        for (let i = 0; i < this.definition.length; i++) {
          if (this.definition[i].id == this.phone.id) {
            this.definition.splice(i, 1);
          }
        }
        this.definition.push(this.phone);
        localStorage.setItem("definition", JSON.stringify(this.definition));
        switch (_wholePixel) {
          case 0:
            this.sdkIns.setStreamProfile({
              width: 432,
              height: 768,
              fps: 20,
              bitrate: 1024,
            });
            this.value1 = 0;
            break;
          case 1:
            this.sdkIns.setStreamProfile({
              width: 576,
              height: 1024,
              fps: 30,
              bitrate: 2048,
            });
            this.value1 = 1;
            break;
          case 2:
            this.sdkIns.setStreamProfile({
              width: 720,
              height: 1280,
              fps: 30,
              bitrate: 4096,
            });
            this.value1 = 2;
            break;
          default:
            console.log("0");
        }
      } else {
        if (this.definition) {
          for (let i = 0; i < this.definition.length; i++) {
            if (this.phone.id == this.definition[i].id) {
              this.value1 = this.definition[i].value1;
            }
          }
        }
        this.popupshow = false;
        if (this.value1 == 1) {
          this.sdkIns.setStreamProfile({
            width: 576,
            height: 1024,
            fps: 30,
            bitrate: 2048,
          });
        } else if (this.value1 == 2) {
          this.sdkIns.setStreamProfile({
            width: 720,
            height: 1280,
            fps: 30,
            bitrate: 4096,
          });
        } else if (this.value1 == 0) {
          this.sdkIns.setStreamProfile({
            width: 432,
            height: 768,
            fps: 20,
            bitrate: 1024,
          });
        } else {
          this.sdkIns.setStreamProfile({
            width: 720,
            height: 1280,
            fps: 30,
            bitrate: 4096,
          });
        }
      }
    },
    // 悬浮窗
    xuanfuchuan() {
      this.popupshow = true;
    },
    menuRorClick(text) {
      switch (text) {
        case "全屏":
          this.screenSize(true);
          break;
        case "剪切板":
          this.jianqieban();
          break;
        case "客服":
          this.pageService();
          break;
        case "续时":
          this.xushi();
          break;
        case "重启":
          this.allShow = true;
          break;
        default:
          console.error("text名称不匹配");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  border: none;
  background: none;
}
/deep/ .van-toast {
  background-color: initial;
  z-index: 99999 !important;
}
.full_qp {
  z-index: 2000;
}
.AC_BgsSdk {
  width: 100%;
  height: 100%;
  // padding-bottom: 30px;
}
.rightMenu {
  width: 100%;
}
.phoneBox_qp {
  height: 100% !important;
  padding: 0 !important;
}
.phoneBox_hf {
  // width: 100%;
  height: calc(100% - 80px);
  padding: 40px 40px 40px 0;
  .phoneBox {
    // width: 100%;
    // height: 100%;
    // /deep/ #phoneVideo {
    //   width: 100% !important;
    //   height: 100% !important;
    //   margin: 0 auto !important;
    // }
  }
  .header {
    position: fixed;
    top: 0;
    width: calc(100%);
    height: 40px;
    z-index: 10;
    // padding: 0 16px;
    text-align: left;
    display: flex;
    align-items: center;
    background: #000;
    justify-content: space-between;
    .headerRight {
      img {
        width: 40px;
        height: 40px;
      }
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 25px;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 4px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    .equipment {
      width: 144px;
      height: 100%;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      justify-content: space-between;
      // justify-content: flex-start;
      .CloudIconBox {
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      .bottomImg {
        width: 16px;
        height: 16px;
      }
    }

    .qingxidu {
      width: 54px;
      height: 24px;
      background: #2e2e37;
      border-radius: 6px;
      color: #fff;
      margin-left: 12px;
      line-height: 18px;
      span {
        font-size: 12px;
      }
    }
    .jianqieban {
      min-width: 60px;
      margin: 0 8px;
    }
    .header_zl {
      margin: 2px 0;
      width: 70px;
      height: 24px;
      background: #2e2e37;
      border-radius: 6px;
      margin-left: 10px;
      font-size: 12px;
      span {
        margin-left: 3px;
      }
      .btn_lv {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #04d154;
        font-size: 14px;
        height: 23px;
        // border-radius: 4px;
        // border: 1px solid #00d34d;
        background: none;
      }
      .btn_huang {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fd841a;
        font-size: 14px;
        height: 25px;
        // border-radius: 4px;
        // border: 1px solid #fd841a;
        background: none;
      }
      .btn_hong {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fc364f;
        font-size: 14px;
        height: 25px;
        // border-radius: 4px;
        // border: 1px solid #fc364f;
        background: none;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    .header_qp {
      min-width: 60px;
      margin: 0 8px;
    }
    .header_tc {
      // min-width: 70px;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .rightKey {
    position: fixed;
    right: 0;
    height: 100%;
    width: 40px;
    top: 0;
    background-color: #000;
    padding-top: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    .last {
      margin-top: 16px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    display: flex;
    // width: 90%;
    height: 40px;
    background: #000;
    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
}
.dialogName::-webkit-scrollbar {
  display: none;
}
// 让整个滚动条的宽度为0
.dialogName::-webkit-scrollbar {
  width: 0px;
}
.dialogName {
  bottom: -10%;
  // min-height: 240px;
  width: 290px;
  height: 350px;
  background: #000;
  border-radius: 10px;
  opacity: 0.7;
  padding: 20px 14px;
  box-sizing: border-box;
  overflow: auto;
  .jqb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    .jqbLeft {
      display: flex;
      align-items: flex-end;
      .jqbName {
        font-size: 18px;
        font-weight: 500;
      }
      .jqbBum {
        font-size: 14px;
        font-weight: 400;
        margin: 0 0 2px 4px;
      }
    }
    .jqbRight {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .btn {
      border: none;
      background: none;
      font-size: 14px;
      color: #999;
    }
  }
  .van-swipe-cell {
    margin-top: 10px;
    height: 42px;
    background: #3f3f4b;
    border-radius: 8px;
    // opacity: 0.7;
    opacity: 1;
    /deep/.van-field__control {
      font-size: 13px;
      color: #ffffff;
    }
    /deep/.van-swipe-cell__wrapper {
      display: flex;
      align-items: center;
      .van-cell {
        background: #3f3f4b;
        border-radius: 8px;
        opacity: 1;
      }
      .van-cell::after {
        border: none;
      }
      .van-field__control {
        color: #aaacb5;
      }
      .van_field_colorbai .van-field__control {
        color: #fff !important;
        opacity: 1;
      }
    }
    .jqb_btncopy {
      background: #3f3f4b;
      border: none;
      width: 45px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .actiImg {
      width: 32px;
      height: 32px;
    }
    .van-swipe-cell__right {
      .jqb_delcopy {
        display: inline-block;
        border: none;
        width: 40px;
        height: 100%;
        background: #da3640;
        font-size: 12px;
        color: #fff;
      }
      .jqb_clearcopy {
        background: #f2b20c;
        display: inline-block;
        border: none;
        width: 40px;
        height: 100%;
        font-size: 12px;
        color: #fff;
      }
    }
    img {
      width: 19px;
      height: 19px;
      margin: 13px;
    }
  }
  .jqb_tianjia {
    background: none;
    border: none;
    margin-top: 28px;
  }
}
.van-popup {
  background: none;
  width: calc(100% - 50px);
  margin: 0 auto;
  .kongzhi {
    width: 100%;
    background: #000;
    border-radius: 14px;
    box-sizing: border-box;
    padding: 25px 16px 20px 16px;
    font-size: 14px;
    .topQuit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // .nameLeft {
      //   width: 144px;
      //   height: 32px;
      //   background: yellowgreen;
      // }
      .nameLeft {
        width: 144px;
        height: 100%;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nameLeftBox {
          display: flex;
          line-height: 22px;
        }
        .CloudIconBox {
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
        .bottomImg {
          width: 16px;
          height: 16px;
        }
      }
      .quitBox {
        width: 96px;
        height: 32px;
        background: #2e2e37;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #fff;
        img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }
    }
    .secondBox {
      background: #2e2e37;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 16px 20px;
      margin-top: 15px;
      .networkTop {
        height: 36px;
        border-bottom: 1px solid #373740;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .networkName {
          color: #fff;
          font-size: 15px;
          font-weight: 500;
        }
        .header_zl {
          img {
            width: 18px;
            height: 18px;
            margin-bottom: 4px;
            margin-right: 4px;
          }
          .btn_lv {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #04d154;
            font-size: 14px;
            height: 23px;
            // border-radius: 4px;
            // border: 1px solid #00d34d;
            background: none;
          }
          .btn_huang {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fd841a;
            font-size: 14px;
            height: 25px;
            // border-radius: 4px;
            // border: 1px solid #fd841a;
            background: none;
          }
          .btn_hong {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fc364f;
            font-size: 14px;
            height: 25px;
            // border-radius: 4px;
            // border: 1px solid #fc364f;
            background: none;
          }
        }
      }
      .huazhi {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin-top: 14px;
        .flo_btn {
          border: none; /* 取消边框 */
          padding: 0; /* 取消内边距 */
          width: 78px;
          height: 36px;
          background: #40404d;
          border-radius: 8px;
          font-size: 13px;
          font-weight: 400;
          color: #cccccc;
        }
        .act_btn {
          background: rgb(46, 111, 250);
          border: 1px solid rgb(46, 111, 250);
        }
      }
    }
    .functionList {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .publicBox {
        width: 142px;
        height: 50px;
        background: #2e2e37;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        margin-top: 12px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          margin-top: 2px;
        }
      }
      .red {
        background: #da3640;
      }
    }
  }
  .tuichuysj {
    width: 100%;
    height: 102px;
    color: #fff;
    margin-top: 10px;
    font-size: 14px;
    background: #000000;
    border-radius: 14px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    .common {
      width: 30%;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
/deep/.custom-overlay {
  background-color: transparent;
}
.gonggexz {
  top: 40px;
  left: 145px;
  border-radius: 8px;
  padding: 4px 0;
  width: 100px;
  height: 122px;
  background: #000000;
  opacity: 0.7;
  color: #fff;
  .gonggeqh {
    width: 98px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      // color: #333333;
      line-height: 16px;
    }
  }
}
.fullLocation {
  top: 200px;
  left: 109px;
}
.keyLocation {
  top: 40px;
  left: 6px;
}
.equPopup {
  width: 156px;
  max-height: 240px;
  background: #000;
  border-radius: 8px;
  opacity: 0.7;

  padding: 4px 0;
  color: #fff;
  .equList {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    .CloudIconBox {
      margin: 0 8px 0 14px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .quiName {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }
  }
}
.equPopup::-webkit-scrollbar {
  display: none;
}
// 让整个滚动条的宽度为0
.equPopup::-webkit-scrollbar {
  width: 0px;
}

.TitleBox {
  position: relative;
  img {
    width: 16px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(-50%, -50%);
  }
}
.deleteTitle {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-top: 16px;
}
.hintText {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-top: 26px;
  padding: 0 24px;
}
.deleteOperate {
  margin: 38px auto 20px auto;
  .deleteCancel {
    width: 116px;
    height: 40px;
    background: #edeff3;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #9b9b9b;
    margin-right: 16px;
    line-height: 40px;
  }
  .deleteAffirm {
    width: 116px;
    height: 40px;
    background: #558bff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    line-height: 40px;
  }
  .machineCancel {
    width: 25%;
    height: 30px;
    background: #edeff3;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #9b9b9b;
    line-height: 30px;
  }
  .machineAffirm {
    width: 25%;
    height: 30px;
    background: #558bff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
    margin-left: 12px;
  }
  .gram {
    background: #aaacb5;
  }
}
</style>