import server from "@/plugins/http.js";

// 获取验证码
export function getsendCode(data) {
  return server({
    url: '/api/app/sendCode',
    method: 'post',
    data: data,
  })
}
// 下载注册
export function h5channelregister(data) {
  return server({
    url: '/api/h5/activity/channel/register',
    method: 'post',
    data: data,
  })
}
// 验证码登录
export function mobileLogin(data) {
  return server({
    url: '/api/app/mobileLogin',
    method: 'post',
    data: data,
  })
}
// v2登录
export function v2mobileLogin(data) {
  return server({
    url: '/api/app/v2/mobileLogin',
    method: 'post',
    data: data,
  })
}
// 密码登录
export function accountlogin(data) {
  return server({
    url: '/api/app/account/login',
    method: 'post',
    data: data,
  })
}
// 作者登录
export function authorlogin(data) {
  return server({
    url: '/api/app/author/login',
    method: 'post',
    data: data,
  })
}
// 一键登录
export function oneClickLogin(data) {
  return server({
    url: '/api/app/oneClickLogin',
    method: 'post',
    data: data,
  })
}
// 活动app携带id跳转h5登录
export function appPageLogin(params) {
  return server({
    url: `/api/app/third/activity/login/${params}`,
    method: 'get',
  })
}
// 重置密码
export function resetpassword(data) {
  return server({
    url: '/api/app/reset/password',
    method: 'post',
    data: data,
  })
}
// 效验是否有密码
export function usercheckpassword(params) {
  return server({
    url: '/api/app/user/check/password',
    method: 'get',
    params: params,
  })
}
// 修改设置密码
export function modifypassword(data) {
  return server({
    url: '/api/app/modify/password',
    method: 'post',
    data: data,
  })
}
// 查询用户信息
export function userinfo(params) {
  return server({
    url: '/api/app/user/info',
    method: 'get',
    params: params,
  })
}
// 修改用户信息
export function update(data) {
  return server({
    url: '/api/app/user/update',
    method: 'post',
    data: data,
  })
}
// 查询设备
export function instancelist(params) {
  return server({
    url: '/api/app/instance/list',
    method: 'get',
    params: params,
  })
}
// 获取serverToken
export function serverToken(data) {
  return server({
    url: '/api/app/instance/serverToken',
    method: 'post',
    data: data,
  })
}
// 是否领取过注册福利
export function regWelfare(params) {
  return server({
    url: `/api/app/activity/regWelfare`,
    method: 'get',
    params: params,
  })
}
// 埋点
export function reportPage(data) {
  return server({
    url: '/api/app/report/page',
    method: 'post',
    data: data,
  })
}
// 产品下载页埋点(不需要登录)
export function reportPageV2(data) {
  return server({
    url: '/api/app/report/v2/page',
    method: 'post',
    data: data,
  })
}
// 立即领取一台云手机
export function Immediateclaim(data) {
  return server({
    url: '/api/app/activity/h5Receive',
    method: 'post',
    data: data,
  })
}
// 重启云手机
export function commandreboot(data) {
  return server({
    url: '/api/app/command/reboot',
    method: 'post',
    data: data,
  })
}

// 恢复出厂设置
export function commandreset(data) {
  return server({
    url: '/api/app/command/reset',
    method: 'post',
    data: data,
  })
}
// 修改名称
export function instanceupdate(data) {
  return server({
    url: '/api/app/instance/update',
    method: 'post',
    data: data,
  })
}
// 一键新机
export function commandnewPad(data) {
  return server({
    url: '/api/app/command/newPad',
    method: 'post',
    data: data,
  })
}
// 更换设备
export function instancechange(data) {
  return server({
    url: '/api/app/instance/change/' + data.id,
    method: 'post',
    data: data,
  })
}
// 设备截图
export function commandscreenshot(data) {
  return server({
    url: '/api/app/command/screenshot',
    method: 'post',
    data: data,
  })
}
// 获取截图结果
export function commandscreenshotinfo(data) {
  return server({
    url: '/api/app/command/screenshot-info',
    method: 'post',
    data: data,
  })
}

// 获取设备截图改版
export function hsOrBdScreenshot(data) {
  return server({
    url: '/api/admin/app/instance/hsOrBdScreenshot',
    method: 'post',
    data: data
  })
}

export function hsOrBdScreenshot_info(data) {
  return server({
    url: '/api/admin/app/instance/hsOrBdScreenshot-info',
    method: 'post',
    data: data
  })
}
// 获取VIP下的版本和套餐
export function packageversionList(data) {
  return server({
    url: '/api/app/vip/package/versionList',
    method: 'post',
    data: data,
  })
}
// 创建订单
export function ordercreate(data) {
  return server({
    url: '/api/app/order/create',
    method: 'post',
    data: data,
  })
}
// 查询订单状态
export function infooRderNo(params) {
  return server({
    url: `/api/app/order/info/${params}`,
    method: 'get',
  })
}
// 订单记录
export function orderlist(data) {
  return server({
    url: '/api/app/order/list',
    method: 'post',
    data: data,
  })
}
// 消息中心
export function messagelist(data) {
  return server({
    url: '/api/app/message/list',
    method: 'post',
    data: data,
  })
}
// 读取所有未读消息
export function alreadyMessage(data) {
  return server({
    url: '/api/app/message/readAll',
    method: 'post',
    data: data,
  })
}
// 用户注销
export function usercancelAccount(data) {
  return server({
    url: `/api/app/user/cancelAccount?code=${data}`,
    method: 'post',
    // data: data,
  })
}
// 校验是否可以修改手机号
export function checkChangePhone(data) {
  return server({
    url: '/api/app/user/checkChangePhone',
    method: 'post',
    data: data,
  })
}
// 修改手机号
export function userchangePhone(data) {
  return server({
    url: '/api/app/user/changePhone',
    method: 'post',
    data: data,
  })
}
// 获取STS临时token
export function uploadgetSts(params) {
  return server({
    url: '/api/app/upload/getSts',
    method: 'get',
    params: params,
  })
}
// 分页查询脚本作者上传记录
export function authorUploadFileLogList(params) {
  return server({
    url: '/api/authorUploadFileLog/getAuthorUploadFileLogList',
    method: 'get',
    params: params
  })
}
// 据用户id查询
export function appUserList(params) {
  return server({
    url: '/api/app/user/getUserById',
    method: 'get',
    params: params,
  })
}
// 根据id批量删除作者上传记录
export function deleteFileLogByIds(data) {
  return server({
    url: '/api/authorUploadFileLog/deleteAuthorUploadFileLogByIds',
    method: 'delete',
    data: data
  })
}
// 获取签名
export function getSign(params) {
  return server({
    url: '/api/app/upload/getSign',
    method: 'get',
    params: params,
  })
}
// 作者批量上传文件
export function authorBatchFile(data) {
  return server({
    url: '/api/app/instance/authorBatchFile',
    method: 'post',
    data: data,
  })
}
// 获取H5推广套餐
export function commissionpackagelist(params) {
  return server({
    url: '/api/app/vip/h5/commission/package/list',
    method: 'get',
    params: params,
  })
}
// 获取套餐下的设备
export function vipinstance(data) {
  return server({
    url: '/api/app/vip/instance',
    method: 'post',
    data: data,
  })
}
// h5获取当日注册人数
export function userCountlist(params) {
  return server({
    url: '/api/admin/index/h5/userCount',
    method: 'get',
    params: params,
  })
}
// 获取定制版套餐V2
export function customizepackage(params) {
  return server({
    url: '/api/app/vip/v2/customize/package/list',
    method: 'get',
    params: params,
  })
}

// // 新人体验套餐
// export function novicepackage(params) {
//   return server({
//     url: '/api/app/vip/novice/package/list',
//     method: 'get',
//     params: params,
//   })
// }
// 新人体验套餐
export function novicepackage(params) {
  return server({
    url: '/api/app/vip/v3/novice/package/list',
    method: 'get',
    params: params,
  })
}
// 新人体验套餐V4
export function novicepackageV4(params) {
  return server({
    url: '/api/app/vip/v4/novice/package/list',
    method: 'get',
    params: params,
  })
}
// 活动提示
export function activityreminder(params) {
  return server({
    url: '/api/app/activity/reminder',
    method: 'get',
    params: params,
  })
}
// 注册福利渠道列表
export function sysConfigChannel(params) {
  return server({
    url: '/api/common/getSysConfigChannels',
    method: 'get',
    params: params,
  })
}
// 发卡套餐列表 
export function issuepackagelist(params) {
  return server({
    url: '/api/app/issue/package/list',
    method: 'get',
    params: params,
  })
}
// 激活码效验
export function issuecheckcode(data) {
  return server({
    url: '/api/app/issue/check/code',
    method: 'post',
    data: data,
  })
}
// 效验订单编号
export function issuecheckorder(data) {
  return server({
    url: '/api/app/issue/check/order',
    method: 'post',
    data: data,
  })
}
// 获取历史激活码订单 
export function issuecoderecord(params) {
  return server({
    url: '/api/app/issue/code/record',
    method: 'get',
    params: params,
  })
}
// 获取历史激活码订单详情
export function particularsOrderNo(data) {
  return server({
    url: '/api/app/issue/query/orderNo',
    method: 'post',
    data: data,
  })
}
// 获取活动模版
export function activitytemplatelist(params) {
  return server({
    url: '/api/app/activity/template/home/page',
    method: 'get',
    params: params
  })
}
// 选择续时套餐列表
export function activationpackagelist(params) {
  return server({
    url: '/api/app/activation/package/list',
    method: 'get',
    params: params
  })
}
// 选择云手机列表
export function activationlist(query) {
  return server({
    url: '/api/app/activation/list?skuId=' + query,
    method: 'get',
    query: query
  })
}
// 使用新增激活码
export function activationuseadd(data) {
  return server({
    url: '/api/app/activation/use/add',
    method: 'post',
    data: data,
  })
}
// 使用续时激活码
export function activationuseextend(data) {
  return server({
    url: '/api/app/activation/use/extend',
    method: 'post',
    data: data,
  })
}
// 激活码验证SKU
export function activationVerifySku(data) {
  return server({
    url: '/api/app/activation/activationVerifySku',
    method: 'post',
    data: data,
  })
}
// 获取可兑换时长
export function activityrewardtime(params) {
  return server({
    url: '/api/app/activity/reward/time',
    method: 'get',
    params: params
  })
}
// 邀请奖励列表
export function activityrewardlist(data) {
  return server({
    url: '/api/app/activity/reward/list',
    method: 'post',
    data: data,
  })
}
// 邀请注册
export function activityregister(data) {
  return server({
    url: '/api/h5/activity/register',
    method: 'post',
    data: data,
  })
}
// 邀请好友注册送时长
export function activityinvitetime(data) {
  return server({
    url: '/api/app/activity/invite/time',
    method: 'post',
    data: data,
  })
}
// 拼团成功记录
export function groupbookingrecord(params) {
  return server({
    url: '/api/app/group/booking/record',
    method: 'get',
    params: params
  })
}
// 团长团员信息
export function groupbookingmemberinfo(params) {
  return server({
    url: '/api/app/group/booking/member/info',
    method: 'get',
    params: params
  })
}
// 拼团套餐
export function groupbookingpackagelist(params) {
  return server({
    url: '/api/app/group/booking/package/list',
    method: 'get',
    params: params
  })
}
// 团员参与的团历史
export function groupmemberrecord(params) {
  return server({
    url: '/api/app/group/booking/member/record',
    method: 'get',
    params: params
  })
}
// 从链接看拼团情况
export function groupmvsemberinfo(params) {
  return server({
    url: '/api/app/group/booking/commander/info',
    method: 'get',
    params: params
  })
}
// 获取openid
export function getopenId(data) {
  return server({
    url: '/api/app/user/get/openId',
    method: 'post',
    data: data,
  })
}
// 授权记录列表
export function authorizerecordlist(params) {
  return server({
    url: '/api/app/authorize/record/list',
    method: 'get',
    params: params
  })
}
// 授权管理列表
export function authorizelist(params) {
  return server({
    url: '/api/app/authorize/list',
    method: 'get',
    params: params
  })
}
// 取消授权
export function authorizemanage(data) {
  return server({
    url: '/api/app/authorize/v2/manage',
    method: 'post',
    data: data,
  })
}
// 获取可用设备列表
export function authorizeinstancelist(params) {
  return server({
    url: '/api/app/authorize/instance/list',
    method: 'get',
    params: params
  })
}
// 用户授权
export function authorize(data) {
  return server({
    url: '/api/app/authorize',
    method: 'post',
    data: data,
  })
}
// 双12活动过期
export function unread(params) {
  return server({
    url: 'api/app/message/unread',
    method: 'get',
    params: params
  })
}
// 轮播图
export function activityBanner(params) {
  return server({
    url: 'api/app/activity/banner',
    method: 'get',
    params: params
  })
}
// 快速切换账号
export function quickLogin(data) {
  return server({
    url: 'api/app/third/quick/login',
    method: 'post',
    data: data
  })
}
// 获取活动验证码
export function captchaImage(params) {
  return server({
    url: 'api/app/activity/captchaImage',
    method: 'get',
    params: params
  })
}
// 领取注册福利设备
export function activityReceive(data) {
  return server({
    url: 'api/app/activity/receive',
    method: 'post',
    data: data
  })
}
// 兑换码
export function applyRedemptionCode(params) {
  return server({
    url: 'api/app/activation/applyRedemptionCode',
    method: 'get',
    params: params
  })
}
// 满减开关
export function sysConfigByConfigKey(params) {
  return server({
    url: 'api/common/getSysConfigByConfigKey',
    method: 'get',
    params: params
  })
}
// 获取一键新机品牌型号
export function phoneModelList(params) {
  return server({
    url: 'api/admin/app/instance/phoneModelList',
    method: 'get',
    params: params
  })
}
// 签到列表
export function signInlist(data) {
  return server({
    url: 'api/appUserWeeklySign/getAppUserWeeklySignByUserId',
    method: 'post',
    data: data
  })
}
// 点击签到
export function userSign(data) {
  return server({
    url: 'api/appUserWeeklySign/userSign',
    method: 'post',
    data: data
  })
}
// 根据用户设备id套餐升级
export function userSetMenuUpgrade(params) {
  return server({
    url: 'api/app/vip/userSetMenuUpgradeByInstanceId',
    method: 'get',
    params: params
  })
}
// 套餐升级创建订单
export function setMenuUpgradeCreate(data) {
  return server({
    url: 'api/app/order/setMenuUpgradeCreate',
    method: 'post',
    data: data
  })
}
// 获取拼团成功滚动数据
export function groupSuccessRollData(params) {
  return server({
    url: 'api/app/group/booking/getGroupBuyingSuccessRollData',
    method: 'get',
    params: params
  })
}
// 获取红包领取记录
export function redEnvelopeRecord(params) {
  return server({
    url: 'api/app/group/booking/getRedEnvelopeReceiveRecord',
    method: 'get',
    params: params
  })
}
// 根据用户获取春节拼团和可红包数据
export function groupAndRedData(params) {
  return server({
    url: 'api/app/group/booking/getGroupBuyingAndRedEnvelopeDataByUser',
    method: 'get',
    params: params
  })
}
// 根据用户获取春节拼团和可红包数据
export function groupAllPackageType(params) {
  return server({
    url: 'api/app/group/booking/getGroupBuyingAllPackageType',
    method: 'get',
    params: params
  })
}
// 根据用户获取春节拼团和可红包数据
export function openTheRedEnvelopeById(params) {
  return server({
    url: 'api/app/group/booking/openTheRedEnvelopeById',
    method: 'get',
    params: params
  })
}
// 根据用户获取春节拼团和可红包数据
export function groupBuyingById(params) {
  return server({
    url: 'api/app/group/booking/getGroupBuyingById',
    method: 'get',
    params: params
  })
}
// 根据套餐id查询套餐数据
export function packageIdQueryCombo(params) {
  return server({
    url: `api/app/vip/getPackageDataByPackageIds/${params}`,
    method: 'get',
    // params: params
  })
}
