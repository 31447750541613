<template>
  <div
    :class="bannerList.length > 0 ? 'AochenHead' : ''"
    :style="{ 'padding-top': gonggeshow == 1 ? '55px' : '98px' }"
  >
    <div class="main" id="AochenHead">
      <div class="HeadTop">
        <div class="HeadLeft">
          <img
            @click="logoClick"
            class="HeadLogo"
            src="../assets/img/homeAmend/logoIcon.png"
            alt="logo"
          />
          <div class="signInIconBox" @click="signInBut">
            <img
              class="signInIcon"
              src="../assets/img/homeAmend/signInIcon.png"
              alt=""
            />
            <div class="signInIconName">签到</div>
          </div>
          <div
            class="countDownIconBox"
            v-if="countDownObj && countDownObj.groupRenewActivityTime > 0"
            @click="specialPageRemind(countDownObj)"
          >
            <img
              class="countDownIcon"
              src="../assets/img/homeAmend/timeIimitrenew/countDownIcon.png"
              alt=""
            />
            <van-count-down
              class="countDownIconName"
              :time="countDownObj.groupRenewActivityTime * 1000"
            />
          </div>
        </div>

        <!-- <van-button class="btn_xushi" :disabled="Aochen_xianshi" @click="xushi"
          >续时</van-button
        > -->

        <div class="right">
          <div class="gonggeqh" @click="gonggeqhShow">
            <img
              v-if="gonggeshow == 1"
              src="../assets/icons/dankapian.png"
              alt=""
            />
            <img
              v-if="gonggeshow == 4"
              src="../assets/icons/sigongge.png"
              alt=""
            />
            <img
              v-if="gonggeshow == 9"
              src="../assets/icons/jiugongge.png"
              alt=""
            />
            <img
              v-if="gonggeshow == 16"
              src="../assets/icons/shiliugongge.png"
              alt=""
            />
            <span v-if="gonggeshow == 1">单卡片</span>
            <span v-if="gonggeshow == 4">四宫格</span>
            <span v-if="gonggeshow == 9">九宫格</span>
            <span v-if="gonggeshow == 16">十六宫格</span>
          </div>
          <div class="shuaxin" @click="shuaxin_sb">
            <img src="../assets/img/homeAmend/headReset.png" alt="" />
          </div>
        </div>
      </div>
      <div class="moreBox" v-if="gonggeshow != 1">
        <div class="moreRight" @click="allGroupShow">
          <div>
            <div v-if="selectiveGroup.id == -1">所有分组</div>
            <div v-else>{{ selectiveGroup.groupName }}</div>
          </div>
          <div>
            <img
              v-show="selectgGroupShow"
              src="../assets/img/homeAmend/arrowsTop.png"
              alt=""
            />
            <img
              v-show="!selectgGroupShow"
              src="../assets/img/homeAmend/arrowsButtom.png"
              alt=""
            />
          </div>
        </div>
        <div class="moreLeft" @click="popupshow()">
          <div>批量操作</div>
          <div>
            <img
              v-show="sonBatchShow"
              src="../assets/img/homeAmend/arrowsTop.png"
              alt=""
            />
            <img
              v-show="!sonBatchShow"
              src="../assets/img/homeAmend/arrowsButtom.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="bannerList.length > 0">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item
          v-for="item in bannerList"
          :key="item.id"
          @click="
            bannerPage(item.type, item.h5Url, item.customPackages, item.vipCode)
          "
          ><div>
            <img
              v-if="item.homeImage != null"
              class="swipeImg"
              :src="item.homeImage"
              alt=""
            />
            <img v-else class="swipeImg" :src="item.appImage" alt="" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 切换宫格 -->
    <van-popup v-model="show" class="gonggexz" position="bottom" closeable>
      <div class="tutle">请选择显示模式</div>
      <div class="gongglistBox">
        <div
          :class="['gonggeqh', gonggeshow == 1 ? 'gonggeBorder' : '']"
          @click="gongge(1)"
        >
          <img
            v-if="gonggeshow == 1"
            class="gonggeCheck"
            src="../assets/img/homeAmend/check.png"
            alt=""
          />
          <img
            class="gonggeImg"
            src="../assets/img/homeAmend/fourShow.png"
            alt=""
          />
          <div>单卡片模式</div>
        </div>
        <div
          :class="['gonggeqh', gonggeshow == 4 ? 'gonggeBorder' : '']"
          @click="gongge(4)"
        >
          <img
            v-if="gonggeshow == 4"
            class="gonggeCheck"
            src="../assets/img/homeAmend/check.png"
            alt=""
          />
          <img
            class="gonggeImg"
            src="../assets/img/homeAmend/oneshow.png"
            alt=""
          />
          <div>四宫格模式</div>
        </div>
        <div
          :class="['gonggeqh', gonggeshow == 9 ? 'gonggeBorder' : '']"
          @click="gongge(9)"
        >
          <img
            v-if="gonggeshow == 9"
            class="gonggeCheck"
            src="../assets/img/homeAmend/check.png"
            alt=""
          />
          <img
            class="gonggeImg"
            src="../assets/img/homeAmend/nineShow.png"
            alt=""
          />
          <div>九宫格模式</div>
        </div>
        <div
          :class="['gonggeqh', gonggeshow == 16 ? 'gonggeBorder' : '']"
          @click="gongge(16)"
        >
          <img
            v-if="gonggeshow == 16"
            class="gonggeCheck"
            src="../assets/img/homeAmend/check.png"
            alt=""
          />
          <img
            class="gonggeImg"
            src="../assets/img/homeAmend/sixteenShow.png"
            alt=""
          />
          <div>十六宫格模式</div>
        </div>
      </div>
    </van-popup>

    <!-- 云手机不够 -->
    <van-dialog
      v-model="ysjbgshow"
      title="提示"
      :show-confirm-button="false"
      className="insufficient"
    >
      <p v-if="active == 4 && InstanceList.length <= 2">
        您拥有的设备数量不足2台，暂时无法切换四宫格显示，您可继续购买云手机解锁
      </p>
      <p v-else-if="active == 9 && InstanceList.length <= 5">
        您拥有的设备数量不足5台，暂时无法切换九宫格显示，您可继续购买云手机解锁
      </p>
      <p v-else-if="active == 16 && InstanceList.length <= 10">
        您拥有的设备数量不足10台，暂时无法切换九宫格显示，您可继续购买云手机解锁
      </p>
      <button class="shaohou" @click="ysjbgshow = false">稍后再说</button>
      <button class="goumai" @click="lijigoumai">立即购买</button>
    </van-dialog>
    <BatchPopup ref="Batch_Popup" @batchShow="batchShow" />
    <!-- 设置 -->
    <van-popup
      v-model="selectgGroupShow"
      round
      closeable
      position="bottom"
      class="setting_tc"
    >
      <div class="GroupPopupBox">
        <div class="title">请选择分组</div>
        <div class="contentList">
          <div
            :class="['topAll', selectiveGroup.id == -1 ? 'pitch' : '']"
            @click="allGroup"
          >
            所有分组
          </div>
          <div
            v-for="item in cardList"
            :key="item.id"
            @click="selective(item)"
            :class="['listGroup', item.id == selectiveGroup.id ? 'pitch' : '']"
          >
            {{ item.groupName }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { activityBanner, instancelist } from "../api/index.js";
import { groupList } from "@/api/homeBatch";
import BatchPopup from "@/components/cell/BatchPopup";
export default {
  name: "AochenHead",
  components: {
    BatchPopup,
  },
  props: {
    InstanceList: {
      default: null,
      type: Array,
    },
    countDownObj: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      activeNames: [],
      show: false,
      gonggeshow: 1,
      ysjbgshow: false,
      active: 1,
      bannerList: [],

      sonBatchShow: false,
      selectgGroupShow: false,
      cardList: [],
      headAllList: [],
      selectiveGroup: {
        id: -1,
      },
    };
  },

  mounted() {
    this.getActivityBanner();
    this.getGroupList();
    this.getinstancelist();
    // setTimeout(() => {
    let show = localStorage.getItem("homeMessage");
    if (show != null) {
      this.gonggeshow = Number(show);
    }
    // }, 50);
    if (sessionStorage.getItem("selectiveGroup") != null) {
      this.selectiveGroup = JSON.parse(
        sessionStorage.getItem("selectiveGroup")
      );
    }
  },

  methods: {
    async getinstancelist() {
      await instancelist().then((res) => {
        if (res.code == 200) {
          this.headAllList = res.data;
          // that.$refs.van_swipe.swipeTo(0);
        }
      });
    },
    // 跳转分组续费
    specialPageRemind(em) {
      if (em.overSoldDevice) {
        this.$refs.aochen_peizhis.warningShow();
      } else {
        this.$parent.getinstancelist();
        sessionStorage.setItem("renewal", JSON.stringify(em));
        this.$router.push({
          params: {
            e: em,
          },
          name: "TimeIimitrenew",
        });
      }
    },
    // 获取分组信息
    getGroupList() {
      groupList().then((res) => {
        if (res.code == 200) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].showStatus = false;
          }
          this.cardList = res.data;
        } else {
          // Toast(res.msg);
        }
      });
    },
    //分组
    selective(item) {
      sessionStorage.removeItem("selectiveGroup");
      sessionStorage.setItem("selectiveGroup", JSON.stringify(item));
      this.selectiveGroup = item;
      this.$emit("GroupList", this.selectiveGroup);
      this.selectgGroupShow = false;
    },
    // 所有分组
    allGroup() {
      let data = {
        id: -1,
        instanceList: this.headAllList,
      };
      sessionStorage.removeItem("selectiveGroup");
      // sessionStorage.setItem("selectiveGroup", JSON.stringify(data));
      this.selectiveGroup = data;
      this.$emit("GroupList", this.selectiveGroup);
      this.selectgGroupShow = false;
    },
    allGroupShow() {
      let userdata = JSON.parse(localStorage.getItem("data"));
      if (userdata) {
        // this.aochenphone = em;
        this.selectgGroupShow = !this.selectgGroupShow;
      } else {
        this.$router.push("/login");
      }
    },
    popupshow(em) {
      let userdata = JSON.parse(localStorage.getItem("data"));
      if (userdata) {
        // this.aochenphone = em;
        this.$refs.Batch_Popup.popupshows();
      } else {
        this.$router.push("/login");
      }
    },
    batchShow(batchShow) {
      this.sonBatchShow = batchShow;
    },
    logoClick() {
      let userdata = JSON.parse(localStorage.getItem("data"));
      if (!userdata) {
        this.$router.push("/login");
      } else {
      }
    },
    signInBut() {
      let userdata = JSON.parse(localStorage.getItem("data"));
      if (!userdata) {
        this.$router.push("/login");
      } else {
        this.$emit("signInClick", true);
      }
    },
    gonggeqhShow() {
      let userdata = JSON.parse(localStorage.getItem("data"));
      if (userdata) {
        this.show = true;
      } else {
        this.$router.push("/login");
      }
    },
    // 刷新页面
    shuaxin_sb() {
      let userdata = JSON.parse(localStorage.getItem("data"));
      if (userdata) {
        sessionStorage.removeItem("selectiveGroup");
        let data = {
          id: -1,
          instanceList: this.headAllList,
        };
        this.selectiveGroup = data;
        // this.$parent.getinstancelist();
        this.$router.go(0);
        Toast("刷新成功！");
      } else {
        this.$router.push("/login");
      }
    },
    gongge(e) {
      this.active = e;
      if (e == 1) {
        this.gonggeshow = 1;
        this.show = false;
        this.$parent.gonggeqiehuan(1);
        localStorage.setItem("homeMessage", JSON.stringify(e));
      } else if (e == 4) {
        if (this.InstanceList.length >= 2) {
          this.gonggeshow = 4;
          this.show = false;
          this.$parent.gonggeqiehuan(4);
          localStorage.setItem("homeMessage", JSON.stringify(e));
        } else {
          this.ysjbgshow = true;
        }
      } else if (e == 9) {
        if (this.InstanceList.length >= 5) {
          this.gonggeshow = 9;
          this.show = false;
          this.$parent.gonggeqiehuan(9);
          localStorage.setItem("homeMessage", JSON.stringify(e));
        } else {
          this.ysjbgshow = true;
        }
      } else if (e == 16) {
        if (this.InstanceList.length >= 10) {
          this.gonggeshow = 16;
          this.show = false;
          this.$parent.gonggeqiehuan(16);
          localStorage.setItem("homeMessage", JSON.stringify(e));
        } else {
          this.ysjbgshow = true;
        }
      }
      this.$emit("updateGroup");
    },
    // 跳购买页面
    lijigoumai() {
      // this.$router.push("/AddCloudPhone");
      this.$router.push({ path: "/newcloudphone", query: { VIP: 1 } });
    },
    // 轮播图
    getActivityBanner() {
      activityBanner().then((res) => {
        if (res.code == 200) {
          this.bannerList = res.data;
        }
      });
    },
    //
    bannerPage(type, h5Url, customPackages, vipCode) {
      let userdata = JSON.parse(localStorage.getItem("data"));
      if (userdata) {
        if (h5Url != null && h5Url != "" && type != 8) {
          let pageh5 = h5Url.substring(h5Url.lastIndexOf("/"), h5Url.length);
          this.$router.push(pageh5);
        } else {
          switch (type) {
            case 1:
              // 1.全民推广 2.激活码 3.邀请好友 4.购买设备 5.拼团活动
              this.$router.push("/agencyCentre");
              break;
            case 2:
              this.$router.push("/activationconversion");
              break;
            case 3:
              this.$router.push("/invitefriends");
              break;
            case 4:
              let _vipCode = null;
              if (
                vipCode == "VIP1" ||
                vipCode == "VIP2" ||
                vipCode == "VIP3" ||
                vipCode == "VIP4"
              ) {
                _vipCode = vipCode.slice(-1) - 0;
              } else {
                _vipCode = vipCode;
              }
              this.$router.push({
                path: "/newcloudphone",
                query: { VIP: _vipCode },
              });
              break;
            case 5:
              this.$router.push("/newGroupBooking");
              break;
            case 6:
              this.$router.push("/attemptPlay");
              break;
            case 8:
              const parts = h5Url.split("category-")[1].split("/");
              let type = parts[0];
              let id = parts[1].split(".html")[0];
              let articleFind = JSON.stringify({
                pageSize: 20,
                pageNum: 2,
                type,
              });
              this.$router.push({
                path: "/discoverDetails",
                query: { find: articleFind, id },
              });
              break;
            case 9:
              location.href = customPackages;
              break;
            default:
              console.log("0");
          }
        }
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-swipe {
  width: calc(100% - 28px);
  margin: 0 auto;
  // margin-top: 15px;
  border-radius: 12px 12px 12px 12px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: 72px;
  text-align: center;
}
/deep/ .van-swipe__indicator {
  width: 4px;
  height: 4px;
  background: #ffffff;
  border-radius: 3px 3px 3px 3px;
  opacity: 0.6;
}
/deep/ .van-swipe__indicator--active {
  width: 14px;
  height: 4px;
  background: #ffffff;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
}
/deep/ .van-swipe__indicators {
  margin-bottom: -6px;
}
.swipeImg {
  width: 100%;
  height: 72px;
}
.AochenHead {
  height: 80px;
}
.main {
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 300;
  background-image: url("@/assets/img/homeAmend/headBack.png");
  background-repeat: no-repeat;
  background-size: 100%;
  .HeadTop {
    padding: 0 16px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .HeadLeft {
      display: flex;
      align-items: center;
    }
  }
  .HeadLogo {
    width: 100px;
    height: 24px;
  }

  .countDownIconBox {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .countDownIcon {
      width: 26px;
      height: 26px;
    }
    .countDownIconName {
      font-size: 12px;
      line-height: 12px;
    }
  }
  .signInIconBox {
    display: flex;
    margin-left: 12px;
    flex-direction: column;
    .signInIcon {
      width: 26px;
      height: 26px;
    }
  }
  .signInIconName {
    font-size: 10px;
    color: #333;
    font-weight: bold;
  }

  .btn_xushi {
    width: 76px;
    height: 28px;
    background: #2e6ffa;
    border-radius: 16px;
    border: none;
    color: #fff;
  }
  p {
    flex: 1;
  }
  .right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    .shuaxin {
      width: 32px;
      height: 32px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .gonggeqh {
      // width: 86px;
      padding: 0 12px;
      height: 32px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
      span {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }
    }
  }
}
.moreBox {
  height: 44px;
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 15px;
  .moreRight {
    display: flex;
    img {
      width: 16px;
      margin-top: -1px;
    }
  }
  .moreLeft {
    display: flex;
    img {
      width: 16px;
      margin-top: -1px;
    }
  }
}
.gonggexz {
  // top: 110px;
  // left: 75%;
  // width: 98px;
  height: 400px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  .tutle {
    font-size: 17px;
    font-weight: 500;
    color: #333;
    margin-bottom: 13px;
    margin-top: 14px;
  }
  .gongglistBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
  }
  .gonggeBorder {
    border: 1px solid #0066ff;
  }
  .gonggeqh {
    width: 162px;
    height: 140px;
    background: #f7f7f7;
    border-radius: 6px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    font-size: 13px;
    color: #666;
    position: relative;
    .gonggeImg {
      width: 72px;
      height: 86px;
      margin-bottom: 6px;
    }
    .gonggeCheck {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}
.insufficient {
  width: 300px;
  padding: 0 12px 20px 12px;
  /deep/ .van-dialog__header {
    font-size: 18px;
  }
}
.shaohou {
  width: 115px;
  height: 40px;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #999999;
  font-weight: 500;
  color: #666666;
  font-size: 15px;
  background: #ffffff;
}
.goumai {
  margin-left: 20px;
  width: 115px;
  height: 40px;
  background: #0066ff;
  border-radius: 20px 20px 20px 20px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  border: 1px solid #0066ff;
}
.GroupPopupBox {
  margin-bottom: 41px;
  .pitch {
    color: #0066ff !important;
  }
  .title {
    margin-top: 15px;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333;
    height: 38px;
  }
  .contentList {
    max-height: 350px;
    overflow: auto;
  }

  .contentList::-webkit-scrollbar {
    width: 0 !important;
  }
  .topAll {
    font-size: 16px;
    font-weight: 400;
    color: #666;
  }
  .listGroup {
    font-size: 16px;
    font-weight: 400;
    color: #666;
    margin-top: 24px;
  }
}
</style>