<template>
  <div class="Four_boxgrid">
    <div
      :class="[
        'cardBox',
        gonggeshow == 9 ? 'SudokuBox' : '',
        gonggeshow == 16 ? 'SixteenHousesBox' : '',
      ]"
    >
      <div
        :class="[
          'div_img',
          gonggeshow == 9 ? 'Sudoku' : '',
          gonggeshow == 16 ? 'SixteenHouses' : '',
        ]"
        v-for="(item, index) in InstanceList"
        :key="index"
      >
        <div class="ysj_name">
          <div class="ysj_head_left">
            <div class="CloudIconBox">
              <img
                v-if="
                  (item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V8')
                "
                src="../../assets/icons/biaozhunanzhuo8.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V10')
                "
                src="../../assets/icons/biaozhunanzhuo10.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V8')
                "
                src="../../assets/icons/changwananzhuo8.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V10')
                "
                src="../../assets/icons/changwananzhuo10.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V8')
                "
                src="../../assets/icons/aorananzhuo8.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V10')
                "
                src="../../assets/icons/aorananzhuo10.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V8')
                "
                src="../../assets/icons/zhizunanzhuo8.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V10')
                "
                src="../../assets/icons/zhizunanzhuo10.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V12')
                "
                src="@/assets/icons/biaozhunanzhuo12.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V12')
                "
                src="@/assets/icons/changwananzhuo12.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V12')
                "
                src="@/assets/icons/aorananzhuo12.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V12')
                "
                src="@/assets/icons/zhizunanzhuo12.png"
                alt=""
              />
              <img
                v-if="
                  (item.vipCode != 'VIP1') &
                  (item.vipCode != 'VIP2') &
                  (item.vipCode != 'VIP3') &
                  (item.vipCode != 'VIP4')
                "
                src="../../assets/icons/dingzhianzhuo.png"
                alt=""
              />
            </div>
            <div class="nameItem">
              <div class="instanceName">
                <div class="typeName">{{ item.instanceName }}</div>
                <!-- v-if="item.isAuthorized == 1"  -->
                <div class="yishouquan" v-if="item.isAuthorized == 2">
                  已授权
                </div>
                <div class="beishouquan" v-if="item.isAuthorized == 1">
                  被授权
                </div>
              </div>
              <van-count-down
                class="instanceItem"
                :time="item.countDown"
                :format="
                  item.countDown > 86400000
                    ? '剩余:DD天HH小时mm分钟'
                    : '剩余:HH小时mm分钟ss秒'
                "
              />
            </div>
          </div>
          <div
            v-if="item.isAuthorized != 1"
            class="renewRight"
            @click="xushi(item)"
          >
            续费
          </div>
        </div>
        <div class="weihu" v-if="item.maintenanceStatus == 1">
          <div>
            <img
              class="weihuImg"
              src="../../assets/img/homeAmend/maintainImg.png"
              alt=""
            />
            <p class="weihuTitle">正在维护中...</p>
          </div>
          <div>
            <p>本云手机正在维护中预计维</p>
            <p>护时间为：</p>
            {{ item.maintenanceText }}
            <p>维护时间结束后请点击刷新按钮</p>
            <p>建议维护完成 后重启云手机</p>
            <p>给您带来不便敬请谅解</p>
          </div>
        </div>
        <div
          class="weihu"
          v-else-if="item.overSoldDevice"
          @click="replace(item)"
        >
          <div>
            <img
              class="weihuImg"
              src="../../assets/img/homeAmend/malfunction.png"
              alt=""
            />
            <p style="color: red" class="weihuTitle">云手机故障...</p>
          </div>
          <div>
            <p>点击自动更换一台</p>
          </div>
          <div class="weihuButton">立即更换</div>
        </div>
        <div
          v-else
          @click="BgsSdk_child(item, index)"
          class="feiweihu"
          :data_ref="item.instanceCode"
        >
          <img
            class="imgs"
            v-if="shotinfoList && shotinfoList.length != 0 && imageurl(item)"
            alt=""
            :src="imageurl(item)"
          />
          <img
            v-else
            class="imgs"
            src="../../assets/img/homeAmend/loadLine.png"
            alt=""
          />
        </div>
        <div
          class="remind"
          v-if="item.remind < 3 && item.statusShow && item.isAuthorized != 1"
        >
          <img
            @click="bad(index, item)"
            class="x_remind"
            src="../../assets/img/homeAmend/remind_x.png"
            alt=""
          />
          <div
            :class="
              atPresent_shake(item.instanceCode) != -1 ? 'shake-text' : ''
            "
          >
            <div class="title_remind">到期提醒</div>
            <div class="text_remind" @click="pageRemind(item)">
              云手机时长<span>不足{{ item.remind + 1 }}天</span
              >，到期将清空数据<br />
              <span>送您一个续费优惠</span>，点击立即领取使用
            </div>
          </div>
        </div>
        <div
          class="sevenExpiration"
          v-if="
            item.remind >= 3 &&
            item.remind < 7 &&
            item.statusShow &&
            item.isAuthorized != 1
          "
        >
          <img
            class="FrameName"
            src="../../assets/img/homeAmend/Frame.png"
            alt=""
          />
          <div class="text" @click="pageRemind(item)">
            云手机时长不足{{ item.remind + 1 }}天，到期将清空数据，请及时<span
              >续费</span
            >
          </div>
          <img
            @click="bad(index, item)"
            class="sevenX"
            src="../../assets/img/homeAmend/sevenX.png"
            alt=""
          />
        </div>
        <img
          @click="popupshow(item)"
          class="seting"
          src="../../assets/icons/settingm_g.png"
          alt=""
        />
      </div>
      <!-- v-if="indexs + 1 == newInstanceList.length" -->
      <div
        :class="[
          'div_img',
          'lijigoumai',
          gonggeshow == 9 ? 'Sudoku' : '',
          gonggeshow == 16 ? 'SixteenHouses' : '',
        ]"
        @click="lijigoumai"
        v-if="getShow"
      >
        <!-- <button class="ljgm_btn">立即购买</button> -->
        <img
          class="addImg1"
          src="../../assets/img/homeAmend/addCloudPhone.png"
          alt=""
        />
        <img
          class="addImg2"
          src="../../assets/img/homeAmend/addText.png"
          alt=""
        />
      </div>
      <!-- <p class="yeshu">{{ indexs + 1 }}/{{ newInstanceList.length }}</p> -->
    </div>
    <Aochenpeizhi :Aochen_phone="aochenphone" ref="aochen_peizhis" />
  </div>
</template>

<script>
import {
  commandscreenshot,
  commandscreenshotinfo,
  hsOrBdScreenshot,
  hsOrBdScreenshot_info,
} from "@/api/index.js";
import { Toast } from "vant";
import Aochenpeizhi from "@/components/cell/Aochen_peizhi.vue";
export default {
  name: "AochenyunshoujiFourBoxgrid",
  components: {
    Aochenpeizhi,
  },
  props: {
    InstanceList: {
      default: null,
      type: Array,
    },
    gonggeshow: {
      default: null,
      type: Number,
    },
    getShow: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      active: 0,
      screenshotList: [],
      shotinfoList: [],
      screen_Interval: null,
      screen_Timeout: null,
      swiper_stop: true,
      userdata: null,
      aochenphone: null,
      newInstanceList: [],
      setItem: null,
      instanceCodes: [],
      DemandId: [],
      dataGroup: null,
      arrImg: [],
      timer: null,
      recursionStatus: true,
      refreshSpeed: JSON.parse(localStorage.getItem("refreshSpeed")) || 2000,
    };
  },

  mounted() {
    // this.timer = setTimeout(() => {
    //   if (this.InstanceList.length < 2) {
    //     localStorage.setItem("homeMessage", JSON.stringify(1));
    //     this.$router.go(0);
    //   }
    // }, 1000);
    let that = this;
    that.userdata = JSON.parse(localStorage.getItem("data"));
    that.dataGroup = sessionStorage.getItem("selectiveGroup");
    // if (that.userdata && this.InstanceList.length > 0) {
    //   that.getscreenshot();
    //   that.screen_Interval = setInterval(function () {
    //     that.getscreenshot();
    //   }, 5000);
    // }
    this.InstanceListFor();
    if (this.InstanceList) {
      this.newInstanceList = [];
      for (let i = 0; i < this.InstanceList.length; i += 4) {
        this.newInstanceList.push(this.InstanceList.slice(i, i + 4));
      }
    }
    this.scrolling();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrolling);
    document.addEventListener("visibilitychange", this.visibility);
  },
  beforeDestroy() {
    this.recursionStatus = false;
    clearTimeout(this.setTime2);
    this.screen_Interval = null;
    this.setTime2 = null;
    window.removeEventListener("scroll", this.scrolling);
    document.removeEventListener("visibilitychange", this.visibility);
  },
  watch: {
    // 监听切换卡片重新获取当前屏幕下列表的设备截图
    gonggeshow(newV, oldV) {
      if (newV) {
        clearTimeout(this.setTime2);
        this.scrolling();
      }
    },
    InstanceList(newV, oldV) {
      if (newV) {
        this.InstanceListFor();
        clearTimeout(this.setTime2);
        this.scrolling();
      }
    },
  },
  computed: {
    atPresent_shake: {
      get() {
        return function (instanceCode) {
          let status = this.DemandId.indexOf(instanceCode);
          return status;
        };
      },
    },
    imageurl: {
      get() {
        return function (em) {
          if (em) {
            for (let i = 0; i < this.shotinfoList.length; i++) {
              if (em.instanceCode == this.shotinfoList[i].instanceCode) {
                return this.shotinfoList[i].url;
              }
            }
          }
        };
      },
    },
  },
  methods: {
    visibility() {
      if (document.hidden) {
        console.log("H5已切换到后台或手机息屏");
        clearTimeout(this.setTime2);
        this.screen_Interval = null;
      } else {
        console.log("H5已切换到网页");
        clearTimeout(this.setTime2);
        this.screen_Interval = null;
        this.scrolling();
      }
    },
    // 关闭续费提醒，把id和剩余时间存入本地，避免刷新重复出现，及1、3天重新弹窗
    bad(index, item) {
      this.InstanceList[index].statusShow = false;
      let Show = JSON.parse(sessionStorage.getItem("countDownShow"));
      if (Show == null) {
        let _show = [];
        _show.push({ id: item.id, expireSeconds: item.expireSeconds });
        sessionStorage.setItem("countDownShow", JSON.stringify(_show));
      } else {
        // 查找或替换
        let findIndex = Show.findIndex((list) => list.id === item.id);
        if (findIndex !== -1) {
          Show[findIndex] = { id: item.id, expireSeconds: item.expireSeconds }; // 替换
        } else {
          Show.push({ id: item.id, expireSeconds: item.expireSeconds }); // 增加
        }
        sessionStorage.setItem("countDownShow", JSON.stringify(Show));
      }
      this.$forceUpdate();
    },
    pageRemind(em) {
      if (em.overSoldDevice) {
        this.$refs.aochen_peizhis.warningShow();
      } else {
        this.$parent.getinstancelist();
        sessionStorage.setItem("renewal", JSON.stringify(em));
        this.$router.push({
          path: "/renewaltime",
        });
      }
    },
    scrolling(e) {
      // 当前屏幕上下多加渲染的变量
      let num = 0;
      if (this.gonggeshow == 4) {
        num = 2;
      } else if (this.gonggeshow == 9) {
        num = 3;
      } else if (this.gonggeshow == 16) {
        num = 4;
      }
      //延时器
      clearTimeout(this.setItem);
      this.setItem = setTimeout(() => {
        // 获取所有列表节点
        var elements = document.getElementsByClassName("feiweihu");
        let arr = [];
        for (var i = 0; i < elements.length; i++) {
          var rect = elements[i].getBoundingClientRect();
          // 判断元素是否在当前屏幕范围内
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            // 对符合条件的元素进行操作
            arr.push(elements[i].getAttribute("data_ref"));
          }
        }
        let index1 =
          this.instanceCodes.indexOf(arr[0]) - num >= 0
            ? this.instanceCodes.indexOf(arr[0]) - num
            : 0;
        let index2 =
          this.instanceCodes.indexOf(arr[arr.length - 1]) + num <=
          this.instanceCodes.length
            ? this.instanceCodes.indexOf(arr[arr.length - 1]) + num
            : this.instanceCodes.length - 1;
        this.DemandId = this.instanceCodes.slice(index1, index2 + 1);
        // 调用截取当前屏幕id的设备截图
        this.getscreenshot(this.instanceCodes.slice(index1, index2 + 1));
        // arr.push()
      }, 200);
    },
    // 启动云手机
    BgsSdk_child(em, dex) {
      this.screen_Interval = null;
      clearTimeout(this.setTime2);
      this.swiper_stop = false;
      this.$parent.BgsSdca(em, dex);
    },
    popupshow(em) {
      this.aochenphone = em;
      this.$refs.aochen_peizhis.popupshows();
    },
    // 超卖更换设备
    replace(em) {
      this.aochenphone = em;
      this.$refs.aochen_peizhis.querengenghuanshebei();
    },
    // 立即领取
    lijilingqu() {
      this.userdata = JSON.parse(localStorage.getItem("data"));
      if (this.userdata) {
        this.$router.push("/newcomerexperience");
      } else {
        this.$router.push("/login");
      }
    },
    // 立即购买
    lijigoumai() {
      this.userdata = JSON.parse(localStorage.getItem("data"));
      if (this.userdata) {
        // this.$router.push("/AddCloudPhone");
        this.$router.push({ path: "/newcloudphone", query: { VIP: 1 } });
      } else {
        this.$router.push("/login");
      }
    },

    InstanceListFor() {
      let a = this.InstanceList;
      this.instanceCodes = [];
      for (let i = 0; i < a.length; i++) {
        if (a[i].maintenanceStatus != 1) {
          this.instanceCodes.push(a[i].instanceCode);
        }
      }
    },
    // 获取设备截图
    async getscreenshot(instanceCodes) {
      if (instanceCodes.length > 0) {
        let that = this;
        // 超卖不去请求设备截图
        let codeS = [];

        this.InstanceList.forEach((lits) => {
          if (!lits.overSoldDevice) {
            instanceCodes.forEach(function (item) {
              if (lits.instanceCode == item) {
                codeS.push(item);
              }
            });
          }
        });
        let data = {
          instanceCodes: codeS,
          quality: 3,
        };
        await hsOrBdScreenshot(data).then((res) => {
          if (res.code == 200) {
            that.screenshotList = res.data;
            clearTimeout(that.screen_Timeout);
            that.screen_Timeout = setTimeout(function () {
              that.getscreenshotinfo();
            }, 1000);
            clearTimeout(this.setTime2);
            this.setTime2 = setTimeout(() => {
              if (this.recursionStatus) {
                this.getscreenshot(instanceCodes);
              }
              // this.scrolling()
            }, this.refreshSpeed);
          } else {
            // Toast(res.msg);
            // 主页token过期不直接回到登录，删除data，判断本地是否有token点击回到login
            if (res.code == 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("data");
            }
          }
        });
      }
    },
    // 获取截图结果
    async getscreenshotinfo() {
      let that = this;
      let taskIds = [];
      that.screenshotList.forEach((item) => {
        if (item.taskId) {
          taskIds.push({
            instanceCode: item.instanceCode,
            taskId: item.taskId,
          });
        } else {
          //智能云直接返回的url，不需要获取截图结果
          that.shotinfoList = that.mergeArrayById(
            that.shotinfoList,
            that.screenshotList
          );
        }
      });
      if (taskIds && taskIds.length > 0) {
        await hsOrBdScreenshot_info(taskIds).then((res) => {
          if (res.code == 200) {
            that.shotinfoList = that.mergeArrayById(
              that.shotinfoList,
              res.data
            );
          } else {
            Toast(res.msg);
          }
        });
      }
    },
    // 截图缓存函数
    mergeArrayById(arr1, arr2) {
      arr2.forEach((item2) => {
        const index = arr1.findIndex(
          (item1) => item1.instanceCode === item2.instanceCode
        );
        if (index !== -1) {
          // 如果在arr1中找到了相同的id，则替换该元素
          if (item2.url) {
            this.$set(arr1, index, item2);
          }
        } else {
          // 如果在arr1中没有找到相同的id，则添加该元素
          arr1.push(item2);
        }
      });
      return arr1;
    },
    xushi(em) {
      if (em.overSoldDevice) {
        this.$refs.aochen_peizhis.warningShow();
      } else {
        sessionStorage.setItem("renewal", JSON.stringify(em));
        this.$router.push({
          path: "/renewaltime",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shake-text {
  // display: inline-block;
  // white-space: nowrap;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  font-size: 20px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.Four_boxgrid {
  .SudokuBox {
    display: grid !important;
    grid-template-columns: 32% 32% 32% !important;
  }
  .SixteenHousesBox {
    display: grid !important;
    grid-template-columns: 24% 24% 24% 24% !important;
  }
  .cardBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 14px;
    box-sizing: border-box;
    .Sudoku {
      width: calc(100%);
      height: 196px;
      border-radius: 12px;
      .ysj_name {
        height: 18px;
        border-radius: 5px;
        width: calc(100% - 12px);
        margin-top: 6px;
        font-size: 5px;
        padding: 0 4px;
        .ysj_head_left {
          display: flex;
          .CloudIconBox {
            width: 12px;
            height: 12px;
            border-radius: 12px;
            line-height: 13px;
            img {
              width: 4.5px;
              height: 4.5px;
            }
          }
          .nameItem {
            margin-left: 2px;
            .instanceName {
              font-size: 5px;
              line-height: 6px;
            }
            .instanceItem {
              font-size: 4px;
              line-height: 4px;
              margin-top: 1px;
            }
          }
        }
        .renewRight {
          width: 20px;
          height: 10px;
          background: linear-gradient(154deg, #00c2ff 0%, #0066ff 100%);
          border-radius: 24px;
          line-height: 10px;
          text-align: center;
        }
        .yishouquan {
          float: left;
          width: 13px;
          height: 5px;
          border-radius: 1px 3px 3px 3px;
          font-size: 3px;
          line-height: 5px;
          margin-left: 1px;
          margin-top: 0.5px;
        }
        .beishouquan {
          background: #ff2828;
          float: left;
          width: 13px;
          height: 5px;
          border-radius: 1px 3px 3px 3px;
          font-size: 3px;
          line-height: 5px;
          margin-left: 1px;
          margin-top: 0.5px;
        }
      }
      .seting {
        bottom: 4px;
        right: 4px;
        width: 20px;
        height: 20px;
      }
      .addImg1 {
        width: 28px !important;
        margin-bottom: 6px !important;
      }
      .addImg2 {
        width: 50px !important;
      }
      .remind {
        bottom: 30px;
        width: 98px;
        height: 34px;
        border-radius: 5px;
        .x_remind {
          width: 6px;
          height: 6px;
          padding: 3px;
        }
        .title_remind {
          font-size: 6px;
          margin: 4px 0 4px 0;
          padding-left: 9px;
        }
        .text_remind {
          font-size: 5px;
          span {
            color: #0099ff;
            font-size: 5px;
          }
        }
      }
      .sevenExpiration {
        bottom: 30px;
        width: 98px;
        height: 18px;
        border-radius: 5px;
        .FrameName {
          width: 10px;
          height: 10px;
          margin-right: 3px;
          margin-left: 4px;
        }
        .text {
          font-size: 4px;
          line-height: 5px;
          width: 64px;
          span {
            color: #0099ff;
            font-size: 4px;
          }
        }
        .sevenX {
          width: 7px;
          height: 7px;
          padding-right: 5px;
        }
      }
    }
    .SixteenHouses {
      width: calc(100%);
      height: 146px;
      border-radius: 9px;
      .imgs {
        border-radius: 3px;
      }
      .ysj_name {
        height: 14px;
        border-radius: 4px;
        width: calc(100% - 9px);
        margin-top: 5px;
        font-size: 4px;
        padding: 0 3px;
        .ysj_head_left {
          display: flex;
          .CloudIconBox {
            width: 9px;
            height: 9px;
            border-radius: 9px;
            line-height: 10px;
            img {
              width: 3px;
              height: 3px;
            }
          }
          .nameItem {
            margin-left: 1.5px;
            .instanceName {
              font-size: 4px;
              line-height: 5px;
            }
            .instanceItem {
              font-size: 3.5px;
              line-height: 3.5px;
              margin-top: 0.5px;
            }
          }
        }
        .renewRight {
          width: 15px;
          height: 7.5px;
          background: linear-gradient(154deg, #00c2ff 0%, #0066ff 100%);
          border-radius: 18px;
          line-height: 7.5px;
          text-align: center;
        }
        .yishouquan {
          float: left;
          width: 9.5px;
          height: 4px;
          border-radius: 1px 2.5px 2.5px 2.5px;
          font-size: 2.5px;
          line-height: 4px;
          margin-left: 0.5px;
          margin-top: 0.25px;
        }
        .beishouquan {
          background: #ff2828;
          float: left;
          width: 9.5px;
          height: 4px;
          border-radius: 1px 2.5px 2.5px 2.5px;
          font-size: 2.5px;
          line-height: 4px;
          margin-left: 0.5px;
          margin-top: 0.25px;
        }
      }
      .seting {
        bottom: 3px;
        right: 3px;
        width: 15px;
        height: 15px;
      }
      .addImg1 {
        width: 21px !important;
        margin-bottom: 4.5px !important;
      }
      .addImg2 {
        width: 37.5px !important;
      }
      .remind {
        bottom: 22.5px;
        width: 73.5px;
        height: 25.5px;
        border-radius: 4px;
        .x_remind {
          width: 5px;
          height: 5px;
          padding: 2.25px;
        }
        .title_remind {
          font-size: 4.5px;
          margin: 4px 0 2px 0;
          padding-left: 6.75px;
        }
        .text_remind {
          font-size: 4px;
          span {
            color: #0099ff;
            font-size: 4px;
          }
        }
      }
      .sevenExpiration {
        bottom: 22.5px;
        width: 73.5px;
        height: 13.5px;
        border-radius: 4px;
        .FrameName {
          width: 7.5px;
          height: 7.5px;
          margin-right: 2.5px;
          margin-left: 3px;
        }
        .text {
          font-size: 3px;
          line-height: 4px;
          width: 48px;
          span {
            color: #0099ff;
            font-size: 3px;
          }
        }
        .sevenX {
          width: 5.25px;
          height: 5.2px;
          padding-right: 4px;
        }
      }
    }
    .yeshu {
      width: 100%;
      text-align: center;
    }
  }
  padding-bottom: 60px;
  .div_img {
    position: relative;
    width: calc(50% - 5px);
    height: 298px;
    overflow: hidden;
    border-radius: 20px;
    // margin: 0 10px 10px 0;
    margin-top: 10px;
    border: 1px solid #999999;
    .ysj_name {
      position: absolute;
      left: 0;
      right: 0;
      height: 28px;
      background: #000;
      border-radius: 8px;
      opacity: 0.6;
      width: calc(100% - 20px);
      margin: 0 auto;
      margin-top: 10px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 8px;
      // z-index: 1;
      box-sizing: border-box;
      padding: 0 6px;
      .ysj_head_left {
        display: flex;
        .CloudIconBox {
          width: 16px;
          height: 16px;
          background: #eefdff;
          border-radius: 16px;
          line-height: 16px;
          text-align: center;
          img {
            width: 6px;
            height: 6px;
          }
        }
        .nameItem {
          text-align: left;
          margin-left: 4px;
          display: flex;
          flex-direction: column;
          .instanceName {
            font-size: 8px;
            font-weight: 400;
            color: #ffffff;
            line-height: 10px;
            // margin-bottom: 4px;
            .typeName {
              float: left;
            }
          }
          .instanceItem {
            font-size: 6px;
            font-weight: 400;
            color: #b3b3b3;
            line-height: 6px;
          }
        }
      }
      .renewRight {
        width: 30px;
        height: 14px;
        background: linear-gradient(154deg, #00c2ff 0%, #0066ff 100%);
        border-radius: 24px;
        line-height: 14px;
        text-align: center;
      }
      span {
        text-align: left;
      }
      .yishouquan {
        float: left;
        width: 16px;
        height: 7px;
        background: #3ac984;
        border-radius: 1px 4px 4px 4px;
        display: inline-block;
        font-size: 4px;
        text-align: center;
        line-height: 7px;
        margin-left: 2px;
        margin-top: 1px;
      }
      .beishouquan {
        background: #ff2828;
        float: left;
        width: 16px;
        height: 7px;
        border-radius: 1px 4px 4px 4px;
        display: inline-block;
        font-size: 4px;
        text-align: center;
        line-height: 7px;
        margin-left: 2px;
        margin-top: 1px;
      }
      p {
        flex: 1;
      }
    }
    .shengyu_time {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 32px;
      background: rgba(30, 33, 51, 0.6);
      border-radius: 0px 0px 12px 12px;
      color: #fff;
      text-align: center;
      line-height: 32px;
      font-size: 10px;
    }
    .seting {
      position: absolute;
      bottom: 6px;
      right: 6px;
      width: 30px;
      height: 30px;
    }
    .imgs {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
    }
    .weihu {
      width: calc(100% - 0px);
      height: 100%;
      // background-size: 140px 140px;
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      .weihuImg {
        width: 80px;
      }
      .weihuTitle {
        font-size: 13px;
        font-weight: 400;
        color: #0066ff;
        // line-height: 24px;
        margin-bottom: 6px;
      }
      p {
        margin: 2px 0;
        color: rgb(74, 74, 74);
        font-family: 思源黑体;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: center;
      }
      .weihuButton {
        background: linear-gradient(145deg, #00c2ff 0%, #0066ff 100%);
        padding: 0px 12px;
        height: 20px;
        margin-top: 6px;
        font-size: 8px !important;
        color: #fff;
        border-radius: 20px;
        line-height: 20px;
      }
    }
    .feiweihu {
      height: 100%;
    }
    .remind {
      position: absolute;
      bottom: 42px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 148px;
      height: 48px;
      background: #000;
      border-radius: 8px;
      opacity: 0.7;
      .x_remind {
        float: right;
        width: 9px;
        height: 9px;
        padding: 5px 5px 0 0;
      }
      .title_remind {
        font-size: 8px;
        font-weight: 500;
        color: #fff;
        margin: 6px 0 4px 0;
        text-align: center;
        padding-left: 14px;
      }
      .text_remind {
        font-size: 7px;
        color: #fff;
        span {
          color: #0099ff;
          font-size: 7px;
        }
      }
    }
    .sevenExpiration {
      position: absolute;
      bottom: 44px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 148px;
      height: 28px;
      background: #000;
      border-radius: 8px;
      opacity: 0.6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .FrameName {
        width: 20px;
        height: 20px;
        margin-right: 3px;
        margin-left: 6px;
      }
      .text {
        font-size: 7px;
        color: #fff;
        text-align: left;
        line-height: 9px;
        width: 95px;
        span {
          color: #0066ff;
          font-size: 7px;
        }
      }
      .sevenX {
        width: 10px;
        height: 10px;
        padding-right: 6px;
      }
    }
  }
  .custom_indicator {
    margin-top: 312px;
  }

  .lijilingqu {
    background-image: url("../../assets/img/xinrentiyan.png");
    background-size: 100% 100%;
    margin: 0 12px !important;
  }
  .lijigoumai {
    background-image: url("../../assets/img/homeAmend/headBack.png");
    background-size: 100% 100%;
    // margin: 0 12px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .addImg1 {
      width: 36px;
      margin-bottom: 6px;
    }
    .addImg2 {
      width: 65px;
    }
  }
  .ljgm_btn {
    position: absolute;
    bottom: 50px;
    left: 0;
    border: none;
    background: none;
    margin: 0 47px;
    color: #fff;
    width: calc(100% - 94px);
    height: 40px;
    background: #558bff;
    border-radius: 22px;
  }
  /deep/ .van-tabs__wrap {
    display: none;
  }
  .van-swipe {
    margin: 10px 0 10px 10px;
    width: calc(100% - 10px);
  }
}
</style>