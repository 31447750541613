import { render, staticRenderFns } from "./Singlecard.vue?vue&type=template&id=29621270&scoped=true"
import script from "./Singlecard.vue?vue&type=script&lang=js"
export * from "./Singlecard.vue?vue&type=script&lang=js"
import style0 from "./Singlecard.vue?vue&type=style&index=0&id=29621270&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29621270",
  null
  
)

export default component.exports