<template>
  <div class="Aochen_peizhi">
    <!-- 设置 -->
    <van-popup
      v-model="show"
      round
      closeable
      position="bottom"
      class="setting_tc"
    >
      <div v-if="Aochen_phone">
        <div class="shebei">
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V10') &
              (Aochen_phone.vipCode == 'VIP1')
            "
            src="../../assets/img/yaoqing/biaozhun10.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V8') &
              (Aochen_phone.vipCode == 'VIP1')
            "
            src="../../assets/img/yaoqing/biaozhun8.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V10') &
              (Aochen_phone.vipCode == 'VIP2')
            "
            src="../../assets/img/yaoqing/changwan10.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V8') &
              (Aochen_phone.vipCode == 'VIP2')
            "
            src="../../assets/img/yaoqing/changwan8.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V10') &
              (Aochen_phone.vipCode == 'VIP3')
            "
            src="../../assets/img/yaoqing/aoran10.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V8') &
              (Aochen_phone.vipCode == 'VIP3')
            "
            src="../../assets/img/yaoqing/aoran8.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V10') &
              (Aochen_phone.vipCode == 'VIP4')
            "
            src="../../assets/img/yaoqing/zhizun10.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V8') &
              (Aochen_phone.vipCode == 'VIP4')
            "
            src="../../assets/img/yaoqing/zhizun8.png"
            alt=""
          />

          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V12') &
              (Aochen_phone.vipCode == 'VIP1')
            "
            src="../../assets/img/yaoqing/biaozhun12.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V12') &
              (Aochen_phone.vipCode == 'VIP2')
            "
            src="../../assets/img/yaoqing/changwan12.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V12') &
              (Aochen_phone.vipCode == 'VIP3')
            "
            src="../../assets/img/yaoqing/aoran12.png"
            alt=""
          />
          <img
            v-if="
              (Aochen_phone.deviceVersionCode == 'V12') &
              (Aochen_phone.vipCode == 'VIP4')
            "
            src="../../assets/img/yaoqing/zhizun12.png"
            alt=""
          />
          <img
            v-if="Aochen_phone.vipCode == 'VIP5'"
            src="../../assets/img/yaoqing/dingzhiL.png"
            alt=""
          />
          <div class="shebei_xx">
            <p class="accreditBox">
              {{ Aochen_phone.instanceName }}
              <span v-if="Aochen_phone.isAuthorized == 1">被授权</span>
              <span v-else-if="Aochen_phone.isAuthorized == 2">已授权</span>
            </p>
            <span>剩余时间:{{ Aochen_phone.expireTime }}</span>
          </div>
        </div>
        <div class="shebeigbh" @click="issucopy">
          设备号：<span>{{ Aochen_phone.instanceId }}</span>
          <img src="../../assets/icons/copy.png" alt="" />
        </div>
        <ul>
          <li>
            <img
              @click="querenchongqi"
              src="../../assets/img/homeAmend/restartIcon.png"
              alt=""
            />
            <p>重启</p>
          </li>
          <!-- <li>
          <img src="../../assets/icons/ic_shangchuan.png" alt="" />
          <p>上传文件</p>
        </li> -->
          <li @click="querenhuifuchuchan">
            <img src="../../assets/img/homeAmend/factoryResetIcon.png" alt="" />
            <p>恢复出厂</p>
          </li>
          <li
            @click="querenxiugaimingchen"
            v-if="Aochen_phone.isAuthorized != 1"
          >
            <img src="../../assets/img/homeAmend/nameIcon.png" alt="" />
            <p>修改名称</p>
          </li>
          <li @click="querenyijianxinji">
            <img src="../../assets/img/homeAmend/newAircraft.png" alt="" />
            <p>一键新机</p>
          </li>
          <li
            @click="querengenghuanshebei"
            v-if="Aochen_phone.isAuthorized != 1"
          >
            <img src="../../assets/img/homeAmend/changeIcon.png" alt="" />
            <p>更换设备</p>
          </li>
          <li @click="xushi" v-if="Aochen_phone.isAuthorized != 1">
            <img src="../../assets/img/homeAmend/continueIcon.png" alt="" />
            <p>续费</p>
          </li>
          <li
            @click="upgradePage"
            v-if="
              (Aochen_phone.customize == '0' &&
                Aochen_phone.isAuthorized != 1 &&
                Aochen_phone.vipCode != 'VIP4' &&
                Aochen_phone.deviceVersionCode != 'V12') ||
              (Aochen_phone.deviceVersionCode == 'V8' &&
                Aochen_phone.vipCode != 'VIP3')
            "
          >
            <img src="../../assets/img/homeAmend/upgradeIcon.png" alt="" />
            <p>套餐升级</p>
          </li>
        </ul>
      </div>
    </van-popup>
    <van-dialog
      v-model="chongqi_show"
      title="重启云手机"
      show-cancel-button
      @confirm="chongqi"
      @cancel="cancel"
    >
      <p class="dialogContent">
        是否确定重启云手机？ 重启期间无法进行其他操作！
      </p>
    </van-dialog>
    <van-dialog
      v-model="huifuchuchan_show"
      title="恢复出厂设置"
      confirm-button-text="恢复出厂"
      show-cancel-button
      @confirm="huifuchuchan"
      @cancel="cancel"
    >
      <p class="dialogContent">
        恢复出厂设置将清除手机上的所有数据，建议谨慎操作
      </p>
    </van-dialog>
    <van-dialog
      v-model="xiugaimingchen_show"
      title="修改名称"
      show-cancel-button
      @confirm="xiugaimingchen"
      @cancel="cancel"
    >
      <div class="dialogContent">
        <van-cell-group v-if="Aochen_phone">
          <van-field v-model="instanceName" />
        </van-cell-group>
      </div>
    </van-dialog>
    <van-dialog
      v-model="yijianxinji_show"
      title="一键新机"
      show-cancel-button
      :showConfirmButton="false"
      :showCancelButton="false"
      class="yijianxinjiClass"
    >
      <!-- 
      @confirm="yijianxinji"
      @cancel="cancel" -->
      <img
        @click="xImgCancel"
        class="xImg"
        src="../../assets/img/homeAmend/x.png"
        alt=""
      />
      <p class="dialogContent">
        是否确定随机更换云手机品牌型号？ 期间云手机需要重启无法操作！
      </p>
      <div class="buttonArea">
        <!-- class="newCancel" -->
        <div @click="equipmentSelect">选择品牌</div>
        <div @click="yijianxinji1(1)">平板模式</div>
        <div @click="yijianxinji">随机更换</div>
      </div>
    </van-dialog>
    <van-dialog
      v-model="genghuanshebei_show"
      title="更换设备"
      show-cancel-button
      @confirm="genghuanshebei"
      @cancel="cancel"
    >
      <p class="dialogContent">
        将为您随机更换一台云手机，云手机内数据将自动清空，是否确定更换？（注：更换操作不可逆，确定之后数据将被清空无法找回）
      </p>
    </van-dialog>
    <!-- 重启 -->
    <van-dialog
      v-model="alfunctionShow"
      :showConfirmButton="false"
      width="296px"
    >
      <!-- <div class="TitleBox">
        <div class="deleteTitle">{{ operate }}云手机</div>
        <img
          src="@/assets/img/homeAmend/x.png"
          alt=""
          @click="alfunctionShow = false"
        />
      </div> -->
      <div class="hintText">您的云手机出现故障，请先更换一台！</div>
      <div class="deleteOperate">
        <!-- <button class="button deleteCancel" @click="alfunctionShow = false">
          取消
        </button> -->
        <button class="button deleteAffirm" @click="alfunctionShow = false">
          确认
        </button>
      </div>
    </van-dialog>
    <van-popup
      v-model="pickerShow"
      round
      position="bottom"
      @close="monitorClose"
    >
      <div class="picker_toolbar">
        <button type="button" class="picker_cancel" @click="pickerShow = false">
          取消
        </button>
        <div class="picker_title">选择品牌型号</div>
        <button type="button" class="picker_confirm" @click="onFinish">
          确定
        </button>
      </div>
      <form action="/" v-show="searchShow">
        <van-search
          v-model="brandValue"
          shape="round"
          background="#fff"
          placeholder="搜索品牌关键词"
          @search="onBrandSearch"
        />
      </form>
      <form action="/" v-show="!searchShow">
        <van-search
          v-model="modelValue"
          shape="round"
          background="#fff"
          placeholder="搜索型号关键词"
          @search="onModelSearch"
        />
      </form>

      <van-cascader
        v-model="cascaderValue"
        :options="columns"
        @close="pickerShow = false"
        @change="onConfirm"
        active-color="#1989fa"
        :show-header="false"
        :placeholder="unselectedName"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import {
  commandreboot,
  commandreset,
  instanceupdate,
  commandnewPad,
  instancechange,
  phoneModelList,
  userSetMenuUpgrade,
} from "@/api/index.js";
import { test } from "postcss-pxtorem/lib/pixel-unit-regex";
export default {
  name: "AochenyunshoujiAochenPeizhi",
  inject: ["grandpaGetinstancelist"], // 在孙子组件中注入方法
  props: {
    Aochen_phone: {},
  },
  data() {
    return {
      show: false,
      chongqi_show: false,
      huifuchuchan_show: false,
      xiugaimingchen_show: false,
      yijianxinji_show: false,
      genghuanshebei_show: false,
      instanceName: "",
      alfunctionShow: false,
      pickerShow: false,
      columns: [],
      modelList: [],
      cascaderValue: "",
      selectBrand: {},
      brandValue: "",
      modelValue: "",
      unselectedName: "",
      selectValue: [],
      searchShow: true,
      observer: null,
    };
  },
  mounted() {
    this.getPhoneModelList("", "");
  },
  beforeDestroy() {
    // this.observer.disconnect();
  },
  computed: {},

  methods: {
    // 判断当前tab页
    SearchCurrent(em) {
      let _index = -1;
      this.selectValue.forEach((item, index) => {
        if (item.text == em) {
          _index = index;
        }
      });
      if (this.unselectedName == "请选择品牌" || _index == 0) {
        this.searchShow = true;
      } else {
        this.searchShow = false;
      }
    },
    // 品牌搜索
    onBrandSearch(val) {
      this.selectBrand = {};
      this.cascaderValue = "";
      this.modelValue = "";
      this.getPhoneModelList(val, "");
    },
    // 型号搜索
    onModelSearch(val) {
      this.selectBrand = {};
      if (this.selectValue.length > 0) {
        this.cascaderValue = this.selectValue[0].text;
      }
      this.getPhoneModelList(this.brandValue, val);
    },
    // 查询品牌型号
    getPhoneModelList(brandval, modelValue) {
      this.columns = [];
      phoneModelList().then((res) => {
        if (res.code == 200) {
          this.modelList = res.data.modelList;
          res.data.brandList.forEach((item, index) => {
            this.columns.push({
              text: item.brand,
              value: item.brand,
              children: [],
            });
            res.data.modelList.forEach((list) => {
              if (item.brand == list.brand) {
                this.columns[index].children.push({
                  text: list.label,
                  value: list.model,
                });
              }
            });
          });
          if (brandval != "") {
            this.columns = this.columns.filter((item) => {
              return (
                item.text.toLowerCase().indexOf(brandval.toLowerCase()) != -1
              );
            });
          }
          if (modelValue != "") {
            this.columns.forEach((item, index) => {
              this.columns[index].children = item.children.filter((item) => {
                return (
                  item.text.toLowerCase().indexOf(modelValue.toLowerCase()) !=
                  -1
                );
              });
            });
          }
        }
      });
    },
    // popup取消清理变量
    monitorClose() {
      this.cascaderValue = "";
      this.brandValue = "";
      this.modelValue = "";
    },
    onFinish() {
      if (JSON.stringify(this.selectBrand) === "{}") {
        Toast("品牌和型号为必选");
      } else {
        commandnewPad(this.selectBrand).then((res) => {
          if (res.code == 200) {
            this.pickerShow = false;
            Toast("云手机将在一分钟内重启，期间无法操作！");
          } else {
            Toast(res.msg);
          }
        });
      }
    },
    onConfirm({ value, selectedOptions, tabIndex }) {
      this.selectValue = selectedOptions;
      this.selectBrand = {};
      if (selectedOptions.length >= 2) {
        this.selectBrand = {
          ids: [this.Aochen_phone.id],
          brand: selectedOptions[0].value,
          model: selectedOptions[1].value,
          manufacturer: selectedOptions[0].value,
        };
      }
    },
    popupshows() {
      this.show = !this.show;
    },
    warningShow() {
      this.alfunctionShow = true;
    },
    // 续时页面
    // xushi() {
    //   sessionStorage.setItem("renewal", JSON.stringify(this.Aochen_phone));
    //   this.$router.push({
    //     path: "/renewaltime",
    //   });
    // },
    // 复制
    issucopy() {
      this.copy(this.Aochen_phone.instanceId);
    },

    // 复制方法
    copy(value) {
      var aux = document.createElement("input");
      aux.setAttribute("value", value);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      Toast("复制成功！");
    },
    // 续时页面
    xushi() {
      if (this.Aochen_phone.overSoldDevice) {
        this.alfunctionShow = true;
        this.show = false;
      } else {
        sessionStorage.removeItem("renewal");
        sessionStorage.setItem("renewal", JSON.stringify(this.Aochen_phone));
        this.$router.push({
          path: "/renewaltime",
        });
      }
    },
    upgradePage() {
      userSetMenuUpgrade({ instanceId: this.Aochen_phone.id }).then((res) => {
        if (res.code == 200) {
          this.$router.push({
            path: "/upgradeCloudPhone",
            query: { Aochen_phone: JSON.stringify(this.Aochen_phone) },
          });
        } else {
          Toast(res.msg);
        }
      });
    },
    // 确认重启
    querenchongqi() {
      if (this.Aochen_phone.overSoldDevice) {
        this.alfunctionShow = true;
        this.show = false;
      } else {
        this.show = false;
        this.chongqi_show = true;
      }
    },
    // 确认恢复出厂
    querenhuifuchuchan() {
      if (this.Aochen_phone.overSoldDevice) {
        this.alfunctionShow = true;
        this.show = false;
      } else {
        this.show = false;
        this.huifuchuchan_show = true;
      }
    },
    // 确认修改名称
    querenxiugaimingchen() {
      if (this.Aochen_phone.overSoldDevice) {
        this.alfunctionShow = true;
        this.show = false;
      } else {
        this.show = false;
        this.instanceName = this.Aochen_phone.instanceName;
        this.xiugaimingchen_show = true;
      }
    },
    // 确认一键新机
    querenyijianxinji() {
      if (this.Aochen_phone.overSoldDevice) {
        this.alfunctionShow = true;
        this.show = false;
      } else {
        this.show = false;
        this.yijianxinji_show = true;
      }
    },
    // 确认更换设备
    querengenghuanshebei() {
      this.show = false;
      this.genghuanshebei_show = true;
    },
    // 取消
    cancel() {
      // console.log("cancelcancelcancel");
    },
    // 一键新机
    equipmentSelect() {
      this.getPhoneModelList("", "");
      this.yijianxinji_show = false;
      this.pickerShow = true;
      let that = this;
      this.$nextTick(function () {
        // 选择目标节点
        const targetNode = document.querySelector(".van-tabs__line");
        // 创建一个观察者对象
        that.observer = new MutationObserver(function (
          mutationsList,
          observer
        ) {
          // 若有变动，则会执行到这里
          for (let mutation of mutationsList) {
            if (
              mutation.type === "childList" ||
              mutation.type === "attributes"
            ) {
              // 这里可以执行你需要的操作
              that.unselectedName =
                document.querySelectorAll(".van-cascader__tab").length <= 1
                  ? "请选择品牌"
                  : "请选择型号";
              const tab_active = document.querySelector(".van-tab--active");
              that.SearchCurrent(tab_active.firstChild.innerHTML);
            }
          }
        });
        // 使用配置文件调用observe方法
        const config = { attributes: true, childList: true, subtree: true };

        // 开始观察已配置观察的目标节点
        that.observer.observe(targetNode, config);
      });
    },
    // 取消一键新机
    xImgCancel() {
      this.yijianxinji_show = false;
    },
    // 重启云手机
    async chongqi() {
      let that = this;
      let data = {
        instanceCodes: [that.Aochen_phone.instanceCode],
      };
      await commandreboot(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在一分钟内重启，期间无法操作！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 恢复出厂
    async huifuchuchan() {
      let that = this;
      let data = {
        instanceCodes: [that.Aochen_phone.instanceCode],
      };
      await commandreset(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在三分钟内恢复出厂设置，期间无法操作！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 修改名称
    async xiugaimingchen() {
      let that = this;
      let data = {
        id: that.Aochen_phone.id,
        instanceName: that.instanceName,
      };
      await instanceupdate(data).then((res) => {
        if (res.code == 200) {
          Toast("设备名称修改成功！");
          that.instanceName = "";
          this.grandpaGetinstancelist();
        } else {
          Toast(res.msg);
        }
      });
    },
    // 一键新机
    async yijianxinji() {
      this.yijianxinji_show = false;
      let that = this;
      let data = {
        ids: [that.Aochen_phone.id],
      };
      await commandnewPad(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在一分钟内重启，期间无法操作！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 一键新机
    async yijianxinji1(e) {
      this.yijianxinji_show = false;
      let that = this;
      let data = {
        ids: [that.Aochen_phone.id],
        flag: e,
      };
      await commandnewPad(data).then((res) => {
        if (res.code == 200) {
          Toast("云手机将在一分钟内重启，期间无法操作！");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 更换设备
    async genghuanshebei() {
      let that = this;
      let data = {
        id: that.Aochen_phone.id,
      };
      await instancechange(data).then((res) => {
        if (res.code == 200) {
          Toast("更换设备成功");
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.yijianxinjiClass {
  // position: relative;
  .xImg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 21px;
    right: 16px;
  }
}
.dialogContent {
  padding: 12px 4px;
}
.button {
  border: none;
  background: none;
}
.TitleBox {
  position: relative;
  img {
    width: 16px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(-50%, -50%);
  }
}
.deleteTitle {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-top: 16px;
}
.hintText {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-top: 26px;
  padding: 0 24px;
}
.deleteOperate {
  margin: 20px auto 20px auto;
  .deleteCancel {
    width: 116px;
    height: 40px;
    background: #edeff3;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #9b9b9b;
    margin-right: 16px;
    line-height: 40px;
  }
  .deleteAffirm {
    width: 150px;
    height: 40px;
    background: #558bff;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    line-height: 40px;
  }
  .machineCancel {
    width: 25%;
    height: 30px;
    background: #edeff3;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #9b9b9b;
    line-height: 30px;
  }
  .machineAffirm {
    width: 25%;
    height: 30px;
    background: #558bff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
    margin-left: 12px;
  }
  .gram {
    background: #aaacb5;
  }
}
.setting_tc {
  min-height: 26%;
  p {
    margin: 0;
  }
  .shebei {
    margin: 30px 0 0 24px;
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
    }
    .shebei_xx {
      margin-left: 12px;
      text-align: left;
      span {
        height: 20px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        display: block;
      }
      .accreditBox {
        span {
          display: inline;
        }
      }
      p {
        // margin: 0 0 5px 0;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        // line-height: 22px;
        span {
          margin-left: 4px;
          padding: 1px 5px;
          width: 40px;
          height: 15px;
          background: #ff2828;
          border-radius: 2px 7px 6px 6px;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 13px;
        }
      }
    }
  }
  ul {
    margin: 24px 0;
    padding: 0 24px;
    text-align: left;
    li {
      text-align: center;
      display: inline-block;
      width: 25%;
      font-size: 14px;
      margin-bottom: 20px;
      img {
        width: 50px;
        height: 50px;
      }
      p {
        font-size: 13px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .shebeigbh {
    margin: 12px 24px;
    width: 152px;
    height: 26px;
    background: #f5f5f5;
    border-radius: 6px 6px 6px 6px;
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
  }
}
.van-dialog {
  /deep/ .van-dialog__header {
    font-size: 16px;
    font-family: NotoSansHans-Medium, NotoSansHans;
    font-weight: 600;
    color: #1e2133;
  }
  p {
    margin: 0 16px;
  }
}

.buttonArea {
  display: flex;
  padding: 5px 20px 20px 20px;
  justify-content: space-between;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  .newCancel {
    background-color: #edeff3;
    color: #000;
  }
  div {
    width: 28%;
    height: 30px;
    line-height: 30px;
    background-color: #558bff;
    border-radius: 5px;
  }
}
/deep/.van-tabs__wrap {
  display: block !important;
  padding-bottom: 18px;
}
/deep/.van-cascader__options {
  padding-top: 0;
}
/deep/.van-cascader__options {
  height: 300px;
}
.picker_toolbar {
  display: flex;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 44px;
}
.picker_cancel {
  height: 100%;
  padding: 0 16px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.picker_confirm {
  height: 100%;
  padding: 0 16px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #576b95;
}
.picker_title {
  max-width: 50%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
</style>