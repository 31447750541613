const state = {
    rticleId: 1,
    couponId: 1,
    couponActioeId: {
    },
    pageNoRecord: true,//弃用，和支付冲突用了持久化模块
    recordLong: true,
    applePushShow: false,
    countDownIconShow: true,
}
const mutations = {
    SET_BATCH_QUIPMENTID: (state, num) => {
        state.rticleId = num
    },
    SET_COUPON: (state, num) => {
        state.couponId = num
    },
    SET_COUPON_ACTIOE: (state, obj) => {
        state.couponActioeId = obj
    },
    SET_ETENTIONPOPUPSHOW: (state, bool) => {
        state.pageNoRecord = bool
    },
    SET_RECORDLONG: (state, bool) => {
        state.recordLong = bool
    },
    SET_APPLEPUSHSHOW: (state, bool) => {
        state.applePushShow = bool
    },
    SET_COUNTDOWNICONSHOW: (state, bool) => {
        state.countDownIconShow = bool
    },
}
export default {
    // 开启命名空间
    namespaced: true,
    mutations,
    state,
}